import { withNavigationWatcher } from './contexts/navigation';
import {
  HomePage,
  LoadingPage,
  //compliance
  CompliancePage,
  ComplianceSave,
  //dashboard
  DashboardPage,
  //master
  CodeMasterPage,
  CostCodeMasterPage,
  CustomerCodeMasterPage,
  AccountMasterPage,
  CostcenterMasterPage,
  VendorMasterPage,
  SeasonTicketMng,
  SeasonTicketMemberMng,
  PGAggregationDataPage,
  SeasonTicketHistoryMng,
  SeasonTicketMemberHistoryMng,
  ExcelUploadSesonTicketMember,
  SeasonTicketMngImp,
  ViewSeasonTicketCardHistory,
  ViewSeasonTicketList,

  //parking
  ParkingMngPage,
  ParkingSystemPage,
  ParkingSalesAggregationInfoPage,

  //sales aggregation
  ViewVANSalesAggregationPage,
  ViewCMSSalesAggregationPage,
  CancelPaymentPage,
  ViewSalesAllPage,
  ViewSalesCostReportPage,
  ViewSalesLambdaHistory,
  ViewRPAJobHistory,
  SuspenseReceiptsMng,

  //sales-mng
  CheckSalesPaymentPage,
  CheckDetailPage,
  HistoryDetailPage,
  EtcSalesMaster,
  SaleClosependingPage,
  SalesTaxinvoicePage,
  CheckSalesTaxinvoicePage,
  CancelSalesPage,
  CancelSalesPageTax,
  CancelSalesConfirmPage,
  SalesTaxinvoiceRecievingPage,
  ParkingCostMgmt,
  ParkingCostViewByMonth,
  // ParkingCostMonthlyReport,
  EtcSalesUpload,
  RentalSalesManagement,
  SalesTaxinvoiceViewPage,
  MonthlyConfirmDataMgmt,
  MonthlyConfirmDataView,
  ViewPaysysRequestStatusPage,
  CMSReplacementUpload,

  //report
  SalesPaymentSummaryPage,
  SalesReportPage,
  SalesPaymentReportPage,
  AccountingSalesReportPage,
  AccountingPaymentReportPage,
  AccountingSalesReportAdminPage,
  UnpayedSalesReportReportPage,
  SalesLockManagement,
  ViewAcountingStatusPage,
  SalesConfirmDataView,
  UnpayedTaxInvoiceReport,
  MailSendHistory,
  //system
  CompanymngPage,
  UsermngPage,
  MenumngPage,
  CostcentermngPage,
  SettingUsePage,
  NoticeMngPage,

  //example
  ProfilePage,
  TasksPage,
  DataGridPage,
  excelUploadPage,
  FileTestPage,
  ChartsPage,

  //tmp
  TaxinvoiceUploadDataPage,
  InputSalesCancelPage,
  CostReportSampleExcel,

  //btob
  CarSharingSalesTaxInvoiceIssuance,
  CarSharingSalesTaxInvoiceViewer,
  CarSharingSalesTaxInvoiceChecker,
  AcquiredSalesTaxInvoiceIssuance,
  AcquiredSalesChecker,
  PCMSalesChecker,
  PCMSalesTaxInvoiceIssuance,
  PCMSalesUploader,

  //iparking, mhp, blueWallet
  PaymentUploadPage,

  //sales-charts
  SalesCharts,
  DMSInterfaceReportPage,

  //cashbill
  CashBillMngPage,
  CashbillListViewPage,
  CashbillListViewAllPage,
} from './pages';

const routes = [
  {
    path: '/home',
    component: HomePage,
  },
  {
    path: '/loading',
    component: LoadingPage,
  },
  {
    path: '/compliance',
    component: CompliancePage,
  },
  {
    path: '/dashboard',
    component: DashboardPage,
  },
  {
    path: '/compliance/save',
    component: ComplianceSave,
  },
  {
    path: '/code-master',
    component: CodeMasterPage,
  },
  {
    path: '/cost-code-master',
    component: CostCodeMasterPage,
  },
  {
    path: '/customer-master',
    component: CustomerCodeMasterPage,
  },
  {
    path: '/parking-mng',
    component: ParkingMngPage,
  },
  {
    path: '/parking-system',
    component: ParkingSystemPage,
  },
  {
    path: '/parking-sales-aggregation',
    component: ParkingSalesAggregationInfoPage,
  },
  {
    path: '/parking-cost-mgmt',
    component: ParkingCostMgmt,
  },
  {
    path: '/parking-cost-mgmt/view-month-cost',
    component: ParkingCostViewByMonth,
  },
  // {
  //   path: '/parking-cost-montly-report',
  //   component: ParkingCostMonthlyReport,
  // },
  {
    path: '/season-ticket-mng',
    component: SeasonTicketMng,
  },
  {
    path: '/season-ticket-mng-2023',
    component: SeasonTicketMngImp,
  },
  {
    path: '/season-ticket-member-mng',
    component: SeasonTicketMemberMng,
  },
  {
    path: '/season-ticket-history-mng',
    component: SeasonTicketHistoryMng,
  },
  {
    path: '/season-ticket-member-history-mng',
    component: SeasonTicketMemberHistoryMng,
  },
  {
    path: '/season-ticket-member-upload',
    component: ExcelUploadSesonTicketMember,
  },
  {
    path: '/view-season-ticket-card-history',
    component: ViewSeasonTicketCardHistory,
  },
  {
    path: '/view-season-ticket-list',
    component: ViewSeasonTicketList,
  },
  {
    path: '/sales-mng/check-sales-payment',
    component: CheckSalesPaymentPage,
  },
  {
    path: '/sales-mng/check-sales-payment/detail',
    component: CheckDetailPage,
  },
  {
    path: '/sales-mng/history-sales-payment',
    component: CheckSalesPaymentPage,
  },
  {
    path: '/sales-mng/history-sales-payment/detail',
    component: HistoryDetailPage,
  },
  {
    path: '/sales-mng/etc-sales-master',
    component: EtcSalesMaster,
  },
  {
    path: '/sales-mng/etc-sales-upload',
    component: EtcSalesUpload,
  },
  {
    path: '/sales-mng/rental-sales-mgmt',
    component: RentalSalesManagement,
  },
  {
    path: '/sales-mng/sales-closepending',
    component: SaleClosependingPage,
  },
  {
    path: '/sales-mng/sales-taxinvoice',
    component: SalesTaxinvoicePage,
  },
  {
    path: '/sales-mng/sales-taxinvoice-recieving',
    component: SalesTaxinvoiceRecievingPage,
  },
  {
    path: '/sales-mng/sales-taxinvoice-view',
    component: SalesTaxinvoiceViewPage,
  },
  {
    path: '/sales-mng/monthly-confirm-mgmt',
    component: MonthlyConfirmDataMgmt,
  },
  {
    path: '/sales-mng/monthly-confirm-detail',
    component: MonthlyConfirmDataView,
  },
  {
    path: '/sales-mng/view-paysys-requests',
    component: ViewPaysysRequestStatusPage,
  },
  {
    path: '/sales-mng/upload-cms-replacement',
    component: CMSReplacementUpload,
  },
  {
    path: '/sales-mng/cashbill-list-view',
    component: CashbillListViewPage,
  },
  {
    path: '/sales-mng/cashbill-list-view-all',
    component: CashbillListViewAllPage,
  },
  {
    path: '/sales-mng/cancel-sales',
    component: CancelSalesPage,
  },
  {
    path: '/sales-mng/cancel-sales-confirm',
    component: CancelSalesConfirmPage,
  },
  {
    path: '/sales-mng/check-sales-taxinvoice',
    component: CheckSalesTaxinvoicePage,
  },
  {
    path: '/sales-mng/sales-lock-management',
    component: SalesLockManagement,
  },
  {
    path: '/sales-mng/suspense-receipts-mng',
    component: SuspenseReceiptsMng,
  },
  {
    path: '/report/sales-payment-summary',
    component: SalesPaymentSummaryPage,
  },
  {
    path: '/report/sales-payment-report',
    component: SalesPaymentReportPage,
  },
  {
    path: '/report/sales-report',
    component: SalesReportPage,
  },
  {
    path: '/report/accounting-sales-report',
    component: AccountingSalesReportPage,
  },
  {
    path: '/report/accounting-payment-report',
    component: AccountingPaymentReportPage,
  },
  {
    path: '/report/accounting-unpayed-report',
    component: UnpayedSalesReportReportPage,
  },
  {
    path: '/report/view-sales-cost-report',
    component: ViewSalesCostReportPage,
  },
  {
    path: '/report/view-sales-lambda-history',
    component: ViewSalesLambdaHistory,
  },
  {
    path: '/report/view-accounting-status',
    component: ViewAcountingStatusPage,
  },
  {
    path: '/report/view-rpa-schedulejob-history',
    component: ViewRPAJobHistory,
  },
  {
    path: '/report/sales-report-admin',
    component: AccountingSalesReportAdminPage,
  },
  {
    path: '/report/view-sales-confirm-data',
    component: SalesConfirmDataView,
  },
  {
    path: '/report/view-unpaynd-taxinvoice-bydate',
    component: UnpayedTaxInvoiceReport,
  },
  {
    path: '/report/mail-send-history',
    component: MailSendHistory,
  },
  {
    path: '/account-master',
    component: AccountMasterPage,
  },
  {
    path: '/costcenter-master',
    component: CostcenterMasterPage,
  },
  {
    path: '/vendor-master',
    component: VendorMasterPage,
  },
  {
    path: '/pg-sale-aggregation',
    component: PGAggregationDataPage,
  },
  {
    path: '/van-sale-aggregation',
    component: ViewVANSalesAggregationPage,
  },
  {
    path: '/cms-sale-aggregation',
    component: ViewCMSSalesAggregationPage,
  },
  {
    path: '/view-sales-all',
    component: ViewSalesAllPage,
  },
  {
    path: '/cancel-payment',
    component: CancelPaymentPage,
  },
  {
    path: '/system/companymng',
    component: CompanymngPage,
  },
  {
    path: '/system/usermng',
    component: UsermngPage,
  },
  {
    path: '/system/menumng',
    component: MenumngPage,
  },
  {
    path: '/system/costcentermng',
    component: CostcentermngPage,
  },
  {
    path: '/system/settinguser',
    component: SettingUsePage,
  },
  {
    path: '/data/upload',
    component: TaxinvoiceUploadDataPage,
  },
  {
    path: '/data/cancel-sales',
    component: InputSalesCancelPage,
  },
  {
    path: '/report/parking-cost-reportsample',
    component: CostReportSampleExcel,
  },
  {
    path: '/example/grid/data-grid',
    component: DataGridPage,
  },
  {
    path: '/example/tasks',
    component: TasksPage,
  },
  {
    path: '/example/profile',
    component: ProfilePage,
  },
  {
    path: '/example/excel-upload',
    component: excelUploadPage,
  },
  {
    path: '/example/file-test',
    component: FileTestPage,
  },
  {
    path: '/example/charts',
    component: ChartsPage,
  },

  // 카쉐어링 매출 세금계산서 발행
  {
    path: '/btob/carsharing-sales-upload',
    component: CarSharingSalesTaxInvoiceIssuance,
  },
  // 카쉐어링 매출 세금계산서 발행 현황 조회
  {
    path: '/btob/carsharing-invoice-list',
    component: CarSharingSalesTaxInvoiceViewer,
  },
  // 카쉐어링 매출 세금계산서 대사
  {
    path: '/btob/carsharing-sales-check',
    component: CarSharingSalesTaxInvoiceChecker,
  },

  // 카쉐어링 매출 세금계산서 발행
  {
    path: '/btob/pcm-sales-upload',
    component: PCMSalesTaxInvoiceIssuance,
  },
  // 카쉐어링 매출 등록
  {
    path: '/btob/pcm-sales-uploadonly',
    component: PCMSalesUploader,
  },
  // 공유서비스(매입) 매출 등록
  {
    path: '/btob/acquired-sales-upload',
    component: AcquiredSalesTaxInvoiceIssuance,
  },

  // 공유서비스(매입) 매출 대사
  {
    path: '/btob/acquired-sales-check',
    component: AcquiredSalesChecker,
  },
  // 공유서비스(PCM) 매출 대사
  {
    path: '/btob/pcm-sales-check',
    component: PCMSalesChecker,
  },
  // 아이파킹 결제내역 불러오기
  {
    path: '/sales-mng/payment-upload',
    component: PaymentUploadPage,
  },

  // [매출 관리] - 수익배분 - 사업소 비용 관리
  {
    path: '/parking-cost-mgmt',
    component: ParkingCostMgmt,
  },

  // 매출관련 챠트 데모
  {
    path: '/sales-charts',
    component: SalesCharts,
  },
  // DMS 데이터 인터페이스 리포트
  {
    path: '/report/interface-report/interface-report',
    component: DMSInterfaceReportPage,
  },
  // 공지사항 관리페이지
  {
    path: '/system/notice',
    component: NoticeMngPage,
  },
  // 현금영수증 관리페이지
  {
    path: '/sales-mng/cashbill-mng',
    component: CashBillMngPage,
  },
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component),
  };
});
