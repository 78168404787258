import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react';
import moment from 'moment';
import DataSource from 'devextreme/data/data_source';
import axios from 'axios';
import CommonDataGrid from 'common/default-data-grid';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Column, Scrolling, TotalItem, Summary } from 'devextreme-react/data-grid';
import { Lookup } from 'devextreme-react/lookup';
import { makeTaxInvoiceStatusCellRender, notifyS } from 'common/popup/sales-common-util';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { common, error } from 'api';
import CostcenterMaster from 'common/popup/costcenter-master';
import notify from 'devextreme/ui/notify';
import DateBox from 'devextreme-react/date-box';
import $ from 'jquery';
import 'styles/CommonStyles.scss';

const userId = localStorage.getItem('userId');

const AccountingSalesReportPage = () => {
  const maingridRef = useRef({});
  const invoicegridRef = useRef({});
  const costcenterMasterRef = useRef({});
  //검색
  const [dateValue, setDateValue] = useState({
    FromDate: moment().subtract(8, 'day').format('YYYY-MM-DD'),
    ToDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
  });
  // 조회할 사업소 목록 array
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [selectedCostCenter, setSelectedCostCenter] = useState([]);

  const [dataSource, setDataSource] = useState({});
  const [dataSourceInvoice, setDataSourceInvoice] = useState({});

  const [costcenterCode, setCostcenterCode] = useState([]);
  const [costCenterType, setCostCenterType] = useState([]);
  const [detailCheck, setDetailCheck] = useState('N');
  const [byPaymentDate, setPaymentDateCheck] = useState('N');

  const [lookupText, setLookupText] = useState('');
  const taxInvoiceStatusCellRender = useCallback(e => {
    return makeTaxInvoiceStatusCellRender(e);
  }, []);

  useEffect(() => {
    (async () => {
      const result = await common.costcenterDataAll();
      if (result.isOk) {
        setCostCenterType(result.data);
      }
    })();
  }, []);

  const customizeText = cellInfo => {
    return cellInfo.value ? moment(cellInfo.value).format('YYYY-MM-DD HH:mm:ss') : '';
  };

  useEffect(() => {
    if (selectedCostCenter.length === 0) {
      setLookupText('');
      setCostcenterCode([]);
      return;
    }
    const codes = selectedCostCenter.map(a => a.CostCenterCode).join(', ');
    const length = selectedCostCenter.length;

    if (length > 1) {
      const { CostCenterCode: dpCode, CostCenterName: dpName } = selectedCostCenter[0];
      const dpText = `[${dpCode}]${dpName} 외 ${length - 1}건 선택됨`;
      setLookupText(dpText);
      setCostcenterCode(codes);
    } else {
      const dpText = selectedCostCenter
        .map((a, i) => `[${a.CostCenterCode}] ${a.CostCenterName}${i + 1 !== length ? ', ' : ''}`)
        .join('');
      setLookupText(dpText);
      setCostcenterCode(codes);
    }
  }, [selectedCostCenter]);

  const groupedData = new DataSource({
    store: costCenterType,
    key: 'CostCenterCode',
  });

  const handleFromDate = e => {
    setDateValue({ ...dateValue, FromDate: e.value });
  };
  const handleToDate = e => {
    setDateValue({ ...dateValue, ToDate: e.value });
  };

  const onDetailCheckValueChanged = e => {
    e.value ? setDetailCheck('Y') : setDetailCheck('N');
  };

  const onPaymentDateCheckValueChanged = e => {
    e.value ? setPaymentDateCheck('Y') : setPaymentDateCheck('N');
  };

  const onSearch = async () => {
    if (costcenterCode.length === 0) {
      return alert('사업소를 먼저 선택해주세요');
    }
    try {
      setLoadPanelVisible(true);
      const result = await axios.post(`aggregation/history/getAccountingPaymentReport`, {
        ...dateValue,
        CostCenterCode: costcenterCode,
        LoginID: userId,
        isDetail: detailCheck,
        byPaymentDate: byPaymentDate,
      });
      setDataSource(result.data[0]);
      const result2 = await axios.post(`aggregation/history/getAccountingPaymentReportInvoice`, {
        ...dateValue,
        CostCenterCode: costcenterCode,
        LoginID: userId,
        isDetail: detailCheck,
        byPaymentDate: byPaymentDate,
      });
      setDataSourceInvoice(result2.data[0]);
      notify(
        {
          message: '조회 되었습니다.',
          width: 230,
          position: {
            at: 'top',
            my: 'top',
            of: '#container',
          },
        },
        'success',
      );
      setLoadPanelVisible(false);
    } catch (e) {
      setLoadPanelVisible(false);
      notify(
        {
          message: error.errorMsgCheck(e.response.data.detail),
          width: 230,
          position: {
            at: 'top',
            my: 'top',
            of: '#container',
          },
        },
        'error',
      );

      const { type, title, status, detail, instance } = e.response.data;
      throw Error(JSON.stringify({ type, title, status, detail, instance }));
    }
  };

  const onCellPreparedInvoice = useCallback(e => {
    cellStatusInvoice(e);
  }, []);

  var mergelements = {};
  const mergeStandardColumn = 'InvoiceID';
  const mergeColumn = [
    'TaxInvoiceStatusName',
    'TaxIssueTypeName',
    'InvoiceID',
    'TaxSalesPrice',
    'TaxInvoiceNo',
    'TaxSaleDate',
    'TaxCustomerNo',
    'TaxCorpRegNumber',
    'TaxCustomerCorpName',
    'TaxCostCenterName',
    'TaxHiParkingID',
  ];

  const cellStatusInvoice = e => {
    if (e.data) {
      // const { InvoiceID } = e.data;
      if (e.rowType === 'data' && e.rowIndex > 0 && mergeColumn.includes(e.column.dataField)) {
        // cellElement.addClass('mergecells');
        // cellElement.click(function () {
        //   '.mergecellselected'.removeClass('mergecellselected')(this).addClass('mergecellselected');
        // });
        if (
          e.component.cellValue(e.rowIndex - 1, mergeStandardColumn) ===
          e.component.cellValue(e.rowIndex, mergeStandardColumn)
        ) {
          if (e.component.cellValue(e.rowIndex - 1, e.column.dataField)) {
            var prev = mergelements[e.rowIndex - 1][e.column.dataField];
            if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
            mergelements[e.rowIndex][e.column.dataField] = prev;
            if (prev) {
              $(e.cellElement).css('display', 'none');
              var span = $(prev).attr('rowspan');
              if (span) $(prev).attr('rowspan', Number(span) + 1);
              else $(prev).attr('rowspan', 2);
            }
          } else {
            if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
            mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
          }
        } else {
          if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
          mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
        }
      } else {
        if (!mergelements[e.rowIndex]) mergelements[e.rowIndex] = {};
        mergelements[e.rowIndex][e.column.dataField] = e.cellElement;
      }
    }
  };

  const onSelectionChanged = e => {
    setCostcenterCode(e.selectedItem.CostCenterCode);
    setLookupText(e.selectedItem.CostCenterName);
  };

  const fieldRender = () => {
    return (
      <div className="dx-lookup-field-wrapper">
        <div className={`dx-lookup-field ${!lookupText ? 'dx-lookup-empty' : ''}`}>
          {lookupText || '사업소를 선택하세요'}
        </div>
      </div>
    );
  };

  return (
    <div>
      <h2 className={'content-block detail-title'}>사업소 확정 매출 조회 (계산서 전체 반영)</h2>
      <div className={'content-block customized-lookup'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <CommonDataGrid
            id="main-grid"
            gridRef={maingridRef}
            className={'dx-card wide-card grid-with-status'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={600}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '사업소 매출 수금 조회 목록.xlsx',
            }}
            headerBefore={[
              <div className="label">매출일 :</div>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="FromDate"
                onValueChanged={handleFromDate}
                value={dateValue.FromDate}
              />,
              <span> ~ </span>,
              <DateBox
                type="date"
                displayFormat="yyyy-MM-dd"
                stylingMode="outlined"
                dropDownButtonTemplate={'dropDownButton'}
                useMaskBehavior={true}
                icon={true}
                dataField="ToDate"
                min={dateValue.FromDate}
                value={dateValue.ToDate}
                onValueChanged={handleToDate}
                dateOutOfRangeMessage="시작일보다 빠를 수 없습니다."
              />,
              <Lookup
                labelMode="static"
                stylingMode="outlined"
                dataSource={groupedData}
                valueExpr="CostCenterCode"
                displayExpr="CostCenterName"
                searchExpr="CostCenterName"
                searchEnabled={true}
                dropDownCentered={false}
                width={400}
                placeholder="검색어를 입력해주세요"
                onSelectionChanged={onSelectionChanged}
                mode="search"
                fieldRender={fieldRender}
              />,
              <Button
                stylingMode="text"
                text="복수 선택"
                onClick={() => {
                  costcenterMasterRef.current.open();
                }}
              />,
            ]}
            headerAfter={[
              <CheckBox defaultValue={false} text="일반권 상세    " onValueChanged={onDetailCheckValueChanged} />,
              <CheckBox
                defaultValue={false}
                text="수금일 기준 조회   "
                onValueChanged={onPaymentDateCheckValueChanged}
              />,
              <CheckBox
                defaultValue={false}
                visible={false}
                text="계산서 상세 조회   "
                onValueChanged={onPaymentDateCheckValueChanged}
              />,

              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            {/* <Column dataField={'CostCenterCode'} caption={'P코드'} alignment={'center'} /> */}
            <Column dataField={'HiParkingID'} caption={'P코드'} alignment={'center'} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'left'} />
            <Column dataField={'PaymentDate'} caption={'수금일'} alignment={'center'} />
            <Column dataField={'SalesDate'} caption={'매출일'} alignment={'center'} />
            <Column dataField={'SalesDateOrigin'} caption={'원매출일'} alignment={'center'} />
            <Column dataField={'SalesTypeName'} caption={'매출항목'} alignment={'left'} />
            <Column dataField={'DetailSalesTypeName'} caption={'매출항목상세'} alignment={'left'} />
            <Column dataField={'ApprovalType'} caption={'정상/반품'} alignment={'center'} />
            <Column dataField={'SalesCodeName'} caption={'수금구분'} alignment={'center'} />
            <Column dataField={'PaymentAgency'} caption={'결제사'} alignment={'center'} />
            <Column dataField={'SalesCnt'} caption={'매출수량'} alignment={'right'} />
            <Column dataField={'SalesPrice'} caption={'합계금액'} alignment={'right'} format="#,##0 원" />
            <Column dataField={'AccountingDate'} caption={'대사날짜'} alignment={'center'} />
            <Column dataField={'AccountingNo'} caption={'대사ID'} alignment={'center'} />
            <Column
              dataField={'AccountinRegisterDate'}
              caption={'대사진행일'}
              alignment={'left'}
              customizeText={customizeText}
            />

            <Column dataField={'FromDate'} caption={'매출대상기간From'} alignment={'center'} />
            <Column dataField={'ToDate'} caption={'매출대상기간To'} alignment={'center'} />
            <Column caption={'고객정보'} alignment={'center'}>
              <Column dataField={'VehicleRegistrationNo'} caption={'차량/고객'} alignment={'left'} />
              <Column dataField={'CustomerNo'} caption={'SAP사업자코드'} alignment={'left'} />
              <Column dataField={'CorpRegNumber'} caption={'사업자번호'} alignment={'left'} />
              <Column dataField={'CustomerCorpName'} caption={'사업자명'} alignment={'left'} />
              <Column dataField={'TypeOfBusiness'} caption={'업종'} alignment={'center'} />
              <Column dataField={'TypeOfIndustry'} caption={'업태'} alignment={'center'} />
            </Column>
            <Column caption={'세금계산서 정보'} alignment={'center'}>
              <Column
                dataField={'TaxInvoiceStatusName'}
                caption={'처리상태'}
                width={110}
                cellRender={taxInvoiceStatusCellRender}
              />
              <Column dataField={'TaxIssueTypeName'} caption={'계산서유형'} alignment={'center'} />
              <Column dataField={'TaxSaleDate'} caption={'발행요청일'} alignment={'center'} />
              <Column dataField={'TaxTypeName'} caption={'과세유형'} alignment={'center'} />
              <Column dataField={'TaxInvoiceTypeName'} caption={'발행유형'} width={100} />
              <Column dataField={'InvoiceID'} caption={'HiDMS 계산서번호'} alignment={'center'} />
              <Column dataField={'TaxInvoiceNo'} caption={'세금계산서번호'} alignment={'center'} />
              <Column dataField={'TaxCustomerNo'} caption={'계산서 SAP사업자코드'} alignment={'left'} />
              <Column dataField={'TaxCorpRegNumber'} caption={'계산서 사업자번호'} alignment={'left'} />
              <Column dataField={'TaxCustomerCorpName'} caption={'계산서 사업자명'} alignment={'left'} />
              <Column dataField={'TaxTypeOfBusiness'} caption={'계산서 업종'} alignment={'center'} />
              <Column dataField={'TaxTypeOfIndustry'} caption={'계산서 업태'} alignment={'center'} />
            </Column>

            {/* -- <Column dataField={'TaxInvoiceCustomerCode'} caption={'P코드'} alignment={'center'} />
-- <Column dataField={'TaxInvoiceCorpRegNumber'} caption={'P코드'} alignment={'center'} /> */}

            <Column dataField={'RegisterUser'} caption={'등록자'} alignment={'center'} />
            <Column dataField={'Remark'} caption={'비고'} alignment={'center'} />
            <Column dataField={'MemberDivisionName'} caption={'정기권회원구분'} alignment={'center'} />
            <Column dataField={'BankAccountNo'} caption={'CMS계좌번호'} alignment={'left'} />
            <Column dataField={'TerminalID'} caption={'단말기ID'} alignment={'left'} />
            <Summary>
              <TotalItem column="SalesCnt" summaryType="sum" displayFormat="총 {0}건" />
              <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
        </div>
      </div>
      <PSLoadPanel shadingColor="rgba(255, 255, 255, 0)" position={{ of: '#main-grid' }} visible={loadPanelVisible} />
      <h3 className={'content-block detail-title'}>세금계산서 상세 조회</h3>
      <div className={'content-block customized-lookup'}>
        <div className={'dx-card'} style={{ padding: '5px 40px' }}>
          <CommonDataGrid
            gridRef={invoicegridRef}
            className={'dx-card wide-card grid-with-status'}
            dataSource={dataSourceInvoice}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            isInfiniteScroll={false}
            onCellPrepared={onCellPreparedInvoice}
            height={600}
            isHeader={true}
            isExcel={true}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '세금계산서 상세 조회.xlsx',
            }}
          >
            {/* <Scrolling mode="virtual" rowRenderingMode="virtual" /> */}
            {/* <Column dataField={'CostCenterCode'} caption={'P코드'} alignment={'center'} /> */}
            <Column caption={'세금계산서 정보'} alignment={'center'}>
              <Column
                dataField={'TaxInvoiceStatusName'}
                caption={'처리상태'}
                width={110}
                cellRender={taxInvoiceStatusCellRender}
              />
              <Column dataField={'InvoiceID'} caption={'HiDMS 계산서번호'} alignment={'center'} />
              <Column dataField={'TaxInvoiceNo'} caption={'세금계산서번호'} alignment={'center'} />
              <Column dataField={'TaxHiParkingID'} caption={'P코드'} alignment={'center'} />
              <Column dataField={'TaxCostCenterName'} caption={'사업소명'} alignment={'center'} />
              <Column dataField={'TaxSalesPrice'} caption={'계산서금액'} alignment={'right'} format="#,##0 원" />
              <Column dataField={'TaxIssueTypeName'} caption={'계산서유형'} alignment={'center'} />
              <Column dataField={'TaxTypeName'} caption={'과세유형'} alignment={'center'} />
              <Column dataField={'TaxSaleDate'} caption={'발행요청일'} alignment={'center'} />
              <Column dataField={'TaxCustomerNo'} caption={'SAP사업자코드'} alignment={'left'} />
              <Column dataField={'TaxCorpRegNumber'} caption={'사업자번호'} alignment={'left'} />
              <Column dataField={'TaxCustomerCorpName'} caption={'사업자명'} alignment={'left'} />
              {/* <Column dataField={'TaxTypeOfBusiness'} caption={'계산서 업종'} alignment={'center'} />
              <Column dataField={'TaxTypeOfIndustry'} caption={'계산서 업태'} alignment={'center'} /> */}
            </Column>
            <Column dataField={'HiParkingID'} caption={'P코드'} alignment={'center'} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'left'} />
            <Column dataField={'SalesTypeName'} caption={'매출항목'} alignment={'left'} />
            <Column dataField={'DetailSalesTypeName'} caption={'매출항목상세'} alignment={'left'} />
            <Column dataField={'SalesPrice'} caption={'원매출금액'} alignment={'right'} format="#,##0 원" />
            <Column dataField={'SalesDate'} caption={'원매출일'} alignment={'center'} />
            <Column dataField={'FromDate'} caption={'시작일'} alignment={'center'} />
            <Column dataField={'ToDate'} caption={'종료일'} alignment={'center'} />
            <Column dataField={'Remark'} caption={'비고'} alignment={'center'} />
            <Column caption={'고객정보'} alignment={'center'}>
              <Column dataField={'VehicleRegistrationNo'} caption={'차량/고객'} alignment={'left'} />
              <Column dataField={'CustomerNo'} caption={'SAP사업자코드'} alignment={'left'} />
              <Column dataField={'CorpRegNumber'} caption={'사업자번호'} alignment={'left'} />
              <Column dataField={'CustomerCorpName'} caption={'사업자명'} alignment={'left'} />
              <Column dataField={'TypeOfBusiness'} caption={'업종'} alignment={'center'} />
              <Column dataField={'TypeOfIndustry'} caption={'업태'} alignment={'center'} />
            </Column>

            {/* -- <Column dataField={'TaxInvoiceCustomerCode'} caption={'P코드'} alignment={'center'} />
-- <Column dataField={'TaxInvoiceCorpRegNumber'} caption={'P코드'} alignment={'center'} /> */}

            <Column dataField={'RegisterUser'} caption={'등록자'} alignment={'center'} />
            <Column dataField={'TerminalID'} caption={'단말기ID'} alignment={'center'} />
            <Summary>
              <TotalItem column="HiParkingID" summaryType="count" displayFormat="총 {0}건" />
              <TotalItem column="SalesPrice" summaryType="sum" valueFormat="#,##0 원" displayFormat="합계: {0}" />
            </Summary>
          </CommonDataGrid>
          <CostcenterMaster
            ref={costcenterMasterRef}
            setSelectedCostCenter={setSelectedCostCenter}
            isMulti
            isSalesCostReport
          />
        </div>
      </div>
    </div>
  );
};

export default AccountingSalesReportPage;
