import DataGrid, { Column, Summary, TotalItem, Scrolling } from 'devextreme-react/data-grid';

export const SumByPaymentDataGrid = props => {
  const { gridRef, dataSource } = props;
  return (
    <DataGrid
      ref={gridRef}
      className={'dx-card wide-card'}
      dataSource={dataSource}
      showBorders={true}
      hoverStateEnabled={true}
      stylingMode="outlined"
      isExcel={false}
      isHeader={false}
      visible={false}
    >
      <Scrolling mode="virtual" rowRenderingMode="virtual" />
      <Column dataField={'수금구분'} />
      <Column dataField={'금액'} format="#,##0 원" />
      <Summary>
        <TotalItem column="금액" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
        <TotalItem column="합계금액" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" alignment="right" />
      </Summary>
    </DataGrid>
  );
};
