import DataGrid, { Column, Summary, TotalItem, Scrolling } from 'devextreme-react/data-grid';

export const CardDataGrid = props => {
  const { gridRef, dataSource } = props;
  return (
    <DataGrid
      ref={gridRef}
      className={'dx-card wide-card'}
      dataSource={dataSource}
      stylingMode="outlined"
      visible={false}
      height={500}
    >
      <Scrolling mode="virtual" rowRenderingMode="virtual" />
      <Column dataField={'순번'} width={'40'} />
      <Column dataField={'상태'} />
      <Column dataField={'가맹점명'} />
      <Column dataField={'승인일시'} />
      <Column dataField={'취소일시'} />
      <Column dataField={'승인금액'} />
      <Column dataField={'취소금액'} />
      <Column dataField={'PG수수료'} />
      <Column dataField={'수수료'} />
      <Column dataField={'부가세'} />
      <Column dataField={'정산금액'} />
      <Column dataField={'카드번호'} />
      <Column dataField={'승인번호'} />
      <Column dataField={'할부개월'} />
      <Column dataField={'발급사'} />
      <Column dataField={'매입사'} />
      <Column dataField={'거래번호'} />
      <Column dataField={'주문번호'} />
      <Column dataField={'가맹점ID'} />
      <Summary>
        <TotalItem column="승인금액" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
        <TotalItem column="취소금액" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
        <TotalItem column="PG수수료" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
        <TotalItem column="수수료" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
        <TotalItem column="부가세" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
        <TotalItem column="정산금액" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
      </Summary>
    </DataGrid>
  );
};
