import DataGrid, { Column, Summary, TotalItem, Scrolling } from 'devextreme-react/data-grid';

export const SalesDataGrid = props => {
  const { gridRef, dataSource, searchData } = props;

  return (
    <>
      <DataGrid
        ref={gridRef}
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={true}
        hoverStateEnabled={true}
        stylingMode="outlined"
        isExcel={false}
        isHeader={false}
        visible={false}
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Column dataField={'매출일'} width={'40'} visible={searchData.ByPaymentDate === 'N'} />
        <Column dataField={'수금일'} width={'40'} visible={searchData.ByPaymentDate === 'Y'} />
        <Column dataField={'매출항목'} />
        <Column dataField={'매출항목상세'} />
        <Column dataField={'수금구분'} />
        <Column dataField={'매출수량'} />
        <Column dataField={'합계금액'} format="#,##0" />
        <Summary>
          <TotalItem column="수금구분" displayFormat="수금구분" />
          <TotalItem column="매출수량" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
          <TotalItem column="합계금액" summaryType="sum" valueFormat="#,##0 원" displayFormat="{0}" alignment="right" />
        </Summary>
      </DataGrid>
      ;
    </>
  );
};
