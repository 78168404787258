import { useImperativeHandle, React, useEffect, useMemo, useState, useRef } from 'react';
import { PivotGrid, FieldChooser, FieldPanel, Scrolling } from 'devextreme-react/pivot-grid';

import { autoFitColumnWidth, isVerifiedDomain } from 'utils/common';
import { Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import { createWorksheet1 } from './worksheet/ws1';
import { createWorksheet2 } from './worksheet/ws2';

import { Button } from 'devextreme-react/button';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { common } from 'api';
import { useAuth } from 'contexts/auth';

import EmailPopup from '../email-popup';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import CommonDataGrid from 'common/default-data-grid';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import CustomStore from 'devextreme/data/custom_store';
import axios from 'axios';

import { MainPivotGrid } from './grid/mainPivotGrid';
import { SalesDataGrid } from './grid/salesDataGrid';
import { SumBySalesDataGrid } from './grid/sumBySalesDataGrid';
import { SumByPaymentDataGrid } from './grid/sumByPaymentDataGrid';

const userId = localStorage.getItem('userId');
const DEFAULT_SENDER = 'hidmsadmin@hiparking.co.kr';

const ParkingCostMonthlyReport = props => {
  const { costData, searchData, setLoadPanelVisible, infoDataSource } = props;
  const { user } = useAuth();
  const pivotgridRef = useRef({});
  const salesGridRef = useRef({});
  const sumBySalesGridRef = useRef({});
  const sumByPaymentCodeGridRef = useRef({});

  const emailPopupRef = useRef({});
  const [pivotDataSource, setPivotDataSource] = useState();
  const [salesDataSource, setSalesDataSource] = useState([]);
  const [sumBySalesDataSource, setSumDataSource] = useState([]);
  const [sumByPaymentCodeDataSource, setSumDataSource2] = useState([]);
  const [profitPartner, setProfitPartner] = useState(false);
  const [previewUrl, setPreviewUrl] = useState();

  useEffect(() => {
    if (previewUrl) {
      window.URL.revokeObjectURL(previewUrl);
      setPreviewUrl(null);
    }
  }, [costData]);

  useMemo(() => {
    if (costData) {
      const customDataSource = new CustomStore({
        loadMode: 'raw',
        load: async () => {
          return costData[1];
        },
      });
      const customSumDataSource = new CustomStore({
        loadMode: 'raw',
        load: async () => {
          return costData[2];
        },
      });
      const customSumDataSource2 = new CustomStore({
        loadMode: 'raw',
        load: async () => {
          return costData[3];
        },
      });
      const customPivotDataSource = new PivotGridDataSource({
        fields: [
          {
            dataField: 'SalesMonth',
            caption: '조회월',
            area: 'column',
          },
          {
            dataField: '구분1',
            expanded: true,
            area: 'row',
            width: 60,
          },
          {
            dataField: '구분2',
            area: 'row',
            width: 80,
            sortingMethod: function (a, b) {
              return a.순서 - b.순서; // '순서' 필드를 기준으로 정렬
            },
          },
          {
            dataField: '총계',
            area: 'data',
            dataType: 'number',
            caption: 'Total',
            summaryType: 'sum',
            format: '#,##0',
          },
        ],

        store: new CustomStore({
          loadMode: 'raw',
          load: async () => {
            return costData[0];
          },
        }),
      });
      setPivotDataSource(customPivotDataSource);
      setSalesDataSource(customDataSource);
      setSumDataSource(customSumDataSource);
      setSumDataSource2(customSumDataSource2);
      setProfitPartner(costData[4][0]['ProfitRate-User']);
    }
  }, [costData]);

  const onCellPrepared = e => {
    if (e.cellElement.classList.contains('dx-row-total') && e.cellElement.classList.contains('dx-grandtotal')) {
      const span = e.cellElement.querySelector('span');
      if (span && span.textContent === 'Grand Total') {
        span.textContent = '합계';
      }
    }
  };

  const handleSaveAsFile = (buffer, fileNameXlsx) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileNameXlsx);
  };
  const onExporting = async (Invoker, formData) => {
    if (!costData || costData.length === 0) {
      return alert('엑셀 데이터가 존재하지 않습니다.');
    }

    const sCostCenterName = costData[4][0]['영업점명'];
    const sCostCenterCode = costData[4][0]['영업점코드'];

    const fileNameXlsx = `수배정산서_${sCostCenterName}_${searchData.SalesMonth}.xlsx`;
    const fileNamePdf = `수배정산서_${sCostCenterName}_${searchData.SalesMonth}.pdf`;

    if (Invoker === 'pdf' && previewUrl) {
      const link = document.createElement('a');
      link.href = previewUrl;
      link.download = fileNamePdf;
      link.click();
      return;
    }

    const workbook = new Workbook();
    try {
      const worksheet1 = workbook.addWorksheet('월손익현황');
      await createWorksheet1(worksheet1, searchData, pivotgridRef, profitPartner, costData, Invoker);

      const worksheet2 = workbook.addWorksheet('매출상세내역');
      await createWorksheet2(worksheet2, salesGridRef, sumBySalesGridRef, sumByPaymentCodeGridRef, costData);

      const buffer = await workbook.xlsx.writeBuffer();

      if (Invoker === 'pdf' || Invoker === 'preview') {
        setLoadPanelVisible(true);
        const response = await axios.post('aggregation/convertExcelToPdf', buffer, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          responseType: 'arraybuffer',
        });

        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = window.URL.createObjectURL(pdfBlob);

        if (Invoker === 'pdf') {
          const link = document.createElement('a');
          link.href = pdfUrl;
          link.download = fileNamePdf;
          link.click();
          window.URL.revokeObjectURL(pdfUrl);
        } else if (Invoker === 'preview') {
          setPreviewUrl(pdfUrl);
        }
      } else {
        handleSaveAsFile(buffer, fileNameXlsx);
      }
      setLoadPanelVisible(false);
    } catch (error) {
      setLoadPanelVisible(false);
      console.error('Exporting error:', error);
    }
  };

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onPdfClick = async () => {
    if (!costData || costData[4].length === 0) {
      return alert('사업소를 선택해주세요.');
    }
    onExporting('pdf');
  };

  // const onEmailClick = async () => {
  //   if (!costData || costData[4].length === 0) {
  //     return alert('사업소를 선택해주세요.');
  //   }
  //   const defaultData = {};
  //   defaultData.SenderEmail = isVerifiedDomain(user.Email) ? user.Email : DEFAULT_SENDER;
  //   defaultData.RecipientEmail = costData[4][0]['이메일 수신인'];
  //   defaultData.CcEmail = costData[4][0]['이메일 참조인'];
  //   defaultData.BccEmail = costData[4][0]['이메일 숨은참조인'];
  //   emailPopupRef.current.open(defaultData);
  // };

  return (
    <div style={{ display: 'flex', marginTop: '3px', width: '100%' }}>
      <div className="normal-report" style={{ marginRight: '5px', width: '70%' }}>
        <Toolbar>
          <Item location={'before'}>
            <Button
              icon="file"
              text="미리보기"
              onClick={() => {
                onExporting('preview');
              }}
            />
          </Item>

          <Item location={'after'}>
            {/* <Button icon="email" text="메일 전송" onClick={onEmailClick} hint="수배정산서 이메일 전송" /> */}
            <Button icon="exportpdf" text="PDF 출력" onClick={onPdfClick} hint="PDF 추출" />
            <Button icon="exportxlsx" text="Excel 출력" onClick={onExporting} hint="수배정산서 Excel 다운로드" />
            {/* <Button icon="menu" text="내역 출력" onClick={onPaymentExporting} hint="카드/계좌 내역 Excel 다운로드" /> */}
          </Item>
        </Toolbar>
        {previewUrl && <iframe src={previewUrl} width="100%" height="1000px" title="PDF 미리보기" />}
        <MainPivotGrid
          gridRef={pivotgridRef}
          dataSource={pivotDataSource}
          previewUrl={previewUrl}
          onCellPrepared={onCellPrepared}
        />
        <SalesDataGrid gridRef={salesGridRef} dataSource={salesDataSource} searchData={searchData} />
        <SumBySalesDataGrid gridRef={sumBySalesGridRef} dataSource={sumBySalesDataSource} />
        <SumByPaymentDataGrid gridRef={sumByPaymentCodeGridRef} dataSource={sumByPaymentCodeDataSource} />
        <EmailPopup ref={emailPopupRef} onExporting={onExporting} costData={costData} />
      </div>
      <div style={{ marginRight: '5px', width: '30%' }}>
        <CommonDataGrid
          className={'dx-card wide-card'}
          dataSource={infoDataSource}
          showBorders={true}
          columnAutoWidth={true}
          allowColumnResizing={true}
          columnHidingEnabled={false}
          hoverStateEnabled={true}
          noDataText="사업소 정보가 없습니다."
          height={1000}
          isHeader={true}
          isExcel={false}
        >
          <Scrolling mode="virtual" rowRenderingMode="virtual" />
        </CommonDataGrid>
      </div>
    </div>
  );
};

export default ParkingCostMonthlyReport;
