import { PivotGrid } from 'devextreme-react/pivot-grid';

export const CardPivotGrid = props => {
  const { gridRef, dataSource } = props;
  return (
    <PivotGrid
      ref={gridRef}
      className={'dx-card wide-card payment-pivot'}
      dataSource={dataSource}
      showBorders={true}
      showColumnTotals={true}
      showRowTotals={false}
      showRowGrandTotals={false}
      hoverStateEnabled={true}
      height={1000}
      visible={false}
    >
      {/* <Scrolling mode="virtual" showScrollbar="always" />
      <FieldPanel visible={true} allowFieldDragging={false} showFilterFields={false} />
      <FieldChooser enabled={false} /> */}
    </PivotGrid>
  );
};
