import DataGrid, { Column, Summary, TotalItem, Scrolling } from 'devextreme-react/data-grid';

export const SalesDataGrid = props => {
  const { gridRef, dataSource } = props;
  return (
    <DataGrid
      ref={gridRef}
      className={'dx-card wide-card'}
      dataSource={dataSource}
      showBorders={true}
      hoverStateEnabled={true}
      stylingMode="outlined"
      visible={false}
      height={500}
    >
      <Scrolling mode="virtual" rowRenderingMode="virtual" />
      <Column dataField={'순번'} width={'40'} />
      <Column dataField={'영업점'} />
      <Column dataField={'고객/ 거래처'} />
      <Column dataField={'매출 항목'} />
      <Column dataField={'매출 일자'} />
      <Column dataField={'매출 수량'} />
      <Column dataField={'합계 금액'} valueFormat="#,##0" width={'100'} />
      <Column dataField={'공급가액'} valueFormat="#,##0" width={'100'} />
      <Column dataField={'부가세'} valueFormat="#,##0" width={'100'} />
      <Column dataField={'수금 구분'} />
      <Column dataField={'매출 상세 구분'} />
      <Summary>
        <TotalItem column="매출 수량" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
        <TotalItem column="합계 금액" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
        <TotalItem column="공급가액" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
        <TotalItem column="부가세" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
      </Summary>
    </DataGrid>
  );
};
