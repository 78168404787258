import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import { Button } from 'devextreme-react/button';
import { autoFitColumnWidth, isVerifiedDomain } from 'utils/common';

import { Column, Summary, TotalItem, Scrolling } from 'devextreme-react/data-grid';
import CommonDataGrid from 'common/default-data-grid';

import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import CustomStore from 'devextreme/data/custom_store';

import { createWorksheet1 } from './worksheet/ws1';
import { createWorksheet2 } from './worksheet/ws2';
import { createWorksheet3 } from './worksheet/ws3';

import { createWorksheetCash } from './worksheet-etc/ws1';
import { createWorksheetCard } from './worksheet-etc/ws2';

import { createWorksheetCashV2 } from './worksheet-etc/ws1';
import { createWorksheetCardV2 } from './worksheet-etc/ws2';

import { createWorksheetCashV3 } from './worksheet-etc/ws1';
import { createWorksheetCardV3 } from './worksheet-etc/ws2';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { useAuth } from 'contexts/auth';

import EmailPopup from '../email-popup';
import axios from 'axios';
import notify from 'devextreme/ui/notify';

import { MainPivotGrid } from './grid/mainPivotGrid';
import { SalesDataGrid } from './grid/salesDataGrid';

import { CardDataGrid } from './grid/cardDataGrid';
import { CashDataGrid } from './grid/cashDataGrid';
import { CardPivotGrid } from './grid/cardPivotGrid';

const ViewSalesCostReportExportPage = props => {
  const { costData, searchData, setLoadPanelVisible, infoDataSource } = props;
  const { user } = useAuth();

  const salesRef = useRef({});
  const cashRef = useRef({});
  const cardRef = useRef({});

  const mainPivotRef = useRef({});
  const cardPivotRef = useRef({});

  const emailPopupRef = useRef({});
  const selectBoxRef = useRef(null);

  const DEFAULT_SENDER = 'hidmsadmin@hiparking.co.kr';
  useEffect(() => {
    setTimeout(() => {
      selectBoxRef.current?.instance?.focus();
    }, 500);
  }, []);

  const [mainPivot, setMainPivot] = useState();
  const [salesDataSource, setSalesDataSource] = useState();
  const [cashDataSource, setCashDataSource] = useState();
  const [cardDataSource, setCardDataSource] = useState();
  const [cardPivot, setCardPivot] = useState();
  const [previewUrl, setPreviewUrl] = useState(null);

  useEffect(() => {
    if (previewUrl) {
      window.URL.revokeObjectURL(previewUrl);
      setPreviewUrl(null);
    }
  }, [costData]);
  useMemo(() => {
    // 매출 sheet
    const tempSalesDataSource = new CustomStore({
      loadMode: 'raw',
      load: async () => {
        return costData[1] || [];
      },
    });
    // 이지페이 현금 sheet
    const tempCashDataSource = new CustomStore({
      loadMode: 'raw',
      load: async () => {
        return costData[6] || [];
      },
    });
    // 이지페이 카드 sheet
    const tempCardDataSource = new CustomStore({
      loadMode: 'raw',
      load: async () => {
        return costData[7] || [];
      },
    });
    // 이지페이 카드 (일자별) sheet
    const tempCardPivotSource = new CustomStore({
      loadMode: 'raw',
      load: async () => {
        const modifiedData = costData[7].map(item => {
          if (item.승인일시) {
            item['승인일자'] = item.승인일시.split(' ')[0];
          } else if (item.취소일시) {
            item['승인일자'] = item.취소일시.split(' ')[0];
          }
          return item;
        });
        return modifiedData;
      },
    });
    // 지출 sheet
    const tempMainPivot = new PivotGridDataSource({
      fields: [
        {
          caption: '구분1',
          dataField: '구분1',
          width: 100,
          area: 'row',
          expanded: true,
          sortBy: 'none',
        },
        {
          caption: '구분2',
          dataField: '구분2',
          width: 100,
          area: 'row',
          expanded: true,
          sortBy: 'none',
        },
        {
          caption: '구분3',
          dataField: '구분3',
          width: 100,
          area: 'row',
          expanded: true,
          sortBy: 'none',
          sortingMethod: function (a, b) {
            return a.DetailSort - b.DetailSort; // '순서' 필드를 기준으로 정렬
          },
        },
        {
          caption: '실적',
          dataField: '실적',
          width: 200,
          dataType: 'number',
          format: '#,##0',
          summaryType: 'sum',
          area: 'data',
        },
        {
          caption: '산출근거',
          dataField: '산출근거',
          width: 300,
          dataType: 'string',
          summaryType: 'max',
          area: 'data',
        },
      ],
      store: new CustomStore({
        loadMode: 'raw',
        load: async () => {
          return costData[2] || [];
        },
      }),
    });
    const tempCardPivot = new PivotGridDataSource({
      fields: [
        { width: 180, caption: '가맹점명', dataField: '가맹점명', expanded: true, area: 'row' },
        { width: 180, caption: '승인일자', dataField: '승인일자', expanded: true, area: 'row' },
        {
          caption: '승인금액',
          dataField: '승인금액',
          expanded: true,
          area: 'data',
          summaryType: 'sum',
          dataType: 'number',
        },
        {
          caption: '취소금액',
          dataField: '취소금액',
          expanded: true,
          area: 'data',
          summaryType: 'sum',
          dataType: 'number',
        },
        {
          caption: '합계금액',
          calculateSummaryValue: function (e) {
            const approvalAmount = e.value('승인금액') || 0;
            const cancelAmount = e.value('취소금액') || 0;
            return approvalAmount + cancelAmount;
          },
          summaryType: 'sum',
          dataType: 'number',
          expanded: true,
          area: 'data',
        },
        {
          caption: '승인건수',
          dataField: '승인금액',
          expanded: true,
          area: 'data',
          summaryType: 'count',
          dataType: 'number',
        },
        {
          caption: '취소건수',
          dataField: '취소금액',
          expanded: true,
          area: 'data',
          summaryType: 'count',
          dataType: 'number',
        },
        {
          caption: '합계건수',
          calculateSummaryValue: function (e) {
            const a = e.value('승인건수') || 0;
            const b = e.value('취소건수') || 0;
            return a + b;
          },
          dataType: 'number',
          expanded: true,
          area: 'data',
        },

        {
          caption: 'PG수수료',
          dataField: 'PG수수료',
          expanded: true,
          area: 'data',
          summaryType: 'sum',
          dataType: 'number',
        },
        {
          caption: '수수료',
          dataField: '수수료',
          expanded: true,
          area: 'data',
          summaryType: 'sum',
          dataType: 'number',
        },
        {
          caption: '부가세',
          dataField: '부가세',
          expanded: true,
          area: 'data',
          summaryType: 'sum',
          dataType: 'number',
        },
        {
          caption: '정산금액',
          dataField: '정산금액',
          expanded: true,
          area: 'data',
          summaryType: 'sum',
          dataType: 'number',
        },
      ],
      store: tempCardPivotSource,
    });
    setMainPivot(tempMainPivot);
    setCardPivot(tempCardPivot);

    setSalesDataSource(tempSalesDataSource);
    setCashDataSource(tempCashDataSource);
    setCardDataSource(tempCardDataSource);
  }, [costData]);

  const onExporting = async (Invoker, formData) => {
    if (!costData || costData[3].length === 0) {
      return alert('사업소를 선택해주세요.');
    }
    const sCostCenterName = costData[3][0]['영업점명'];
    const sCostCenterCode = costData[3][0]['영업점코드'];

    const fileNameXlsx = `수배정산서_${sCostCenterName}_${searchData.SalesMonth}.xlsx`;
    const fileNamePdf = `수배정산서_${sCostCenterName}_${searchData.SalesMonth}.pdf`;

    if (Invoker === 'pdf' && previewUrl) {
      const link = document.createElement('a');
      link.href = previewUrl;
      link.download = fileNamePdf;
      link.click();
      return;
    }

    const workbook = new Workbook();
    try {
      const worksheet1 = workbook.addWorksheet('갑지');
      await createWorksheet1(workbook, worksheet1, mainPivot, costData);

      const worksheet2 = workbook.addWorksheet('매출');
      await createWorksheet2(worksheet2, salesRef, costData);

      const worksheet3 = workbook.addWorksheet('지출');
      await createWorksheet3(workbook, worksheet3, mainPivot, mainPivotRef, costData);

      const buffer = await workbook.xlsx.writeBuffer();
      // email 전송
      if (Invoker === 'email') {
        const { RecipientEmail, BccEmail, CcEmail, SenderEmail } = formData;
        const base64Buffer = buffer.toString('base64');
        const res = await axios.post('aggregation/sendEmailWithAttachment', {
          CostCenterCode: sCostCenterCode,
          CostCenterName: sCostCenterName,
          SalesMonth: searchData.SalesMonth,
          SenderEmail,
          RecipientEmail,
          CcEmail,
          BccEmail,
          FileBuffer: base64Buffer,
          FileName: fileNameXlsx,
          SignitureInfo: user,
        });
        if (res.status === 200) {
          notify(
            {
              message: '메일이 전송되었습니다.',
              width: 230,
              position: {
                at: 'top',
                my: 'top',
                of: '#container',
              },
            },
            'success',
          );
          handleSaveAsFile(buffer, fileNameXlsx);
        }
      }
      // preview 이미지 불러오기
      else if (Invoker === 'pdf' || Invoker === 'preview') {
        setLoadPanelVisible(true);
        const response = await axios.post('aggregation/convertExcelToPdf', buffer, {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
          responseType: 'arraybuffer',
        });

        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = window.URL.createObjectURL(pdfBlob);

        if (Invoker === 'pdf') {
          const link = document.createElement('a');
          link.href = pdfUrl;
          link.download = fileNamePdf;
          link.click();

          window.URL.revokeObjectURL(pdfUrl);
        } else if (Invoker === 'preview') {
          setPreviewUrl(pdfUrl);
        }
        setLoadPanelVisible(false);
      } else {
        handleSaveAsFile(buffer, fileNameXlsx);
      }
    } catch (error) {
      setLoadPanelVisible(false);
      notify(
        {
          message: error,
          width: 230,
          position: {
            at: 'top',
            my: 'top',
            of: '#container',
          },
        },
        'error',
      );
    }
  };
  const onPaymentExporting = async () => {
    if (!costData || !costData[6]) {
      return alert('보고서 데이터가 없습니다.');
    }

    const costCenters = costData[3][0]['영업점목록'];
    const sCostCenterNames = JSON.parse(costCenters);
    const bIsMulti = sCostCenterNames.length > 1;
    const bIsSplit = true;

    const workbook = new Workbook();
    const worksheet1 = workbook.addWorksheet('계좌');
    await createWorksheetCash(worksheet1, cashRef, costData);
    // await createWorksheetCardV2(worksheet2, cardPivotRef, costData);
    if (bIsMulti && bIsSplit) {
      sCostCenterNames.forEach((a, i) => {
        const worksheet2 = workbook.addWorksheet(`${a} 카드`);
        const filteredArray = costData[7].filter(item => item.가맹점명 === a);
        createWorksheetCardV3(worksheet2, filteredArray);
      });
    } else {
      const worksheet2 = workbook.addWorksheet(`카드`);
      await createWorksheetCard(worksheet2, cardRef, costData);
    }
    const sCostCenterName = costData[3][0]['영업점명'];
    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const fileNameXlsx = `계좌카드내역_${sCostCenterName}_${searchData.SalesMonth}.xlsx`;

      handleSaveAsFile(buffer, fileNameXlsx);
    } catch (error) {
      notify(
        {
          message: error,
          width: 230,
          position: {
            at: 'top',
            my: 'top',
            of: '#container',
          },
        },
        'error',
      );
    }
  };

  const handleSaveAsFile = (buffer, fileNameXlsx) => {
    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileNameXlsx);
  };

  const onPdfClick = async () => {
    if (!costData || costData[3].length === 0) {
      return alert('사업소를 선택해주세요.');
    }
    onExporting('pdf');
  };

  const onEmailClick = async () => {
    if (!costData || costData[3].length === 0) {
      return alert('사업소를 선택해주세요.');
    }
    const defaultData = {};
    defaultData.SenderEmail = isVerifiedDomain(user.Email) ? user.Email : DEFAULT_SENDER;
    defaultData.RecipientEmail = costData[3][0]['이메일 수신인'];
    defaultData.CcEmail = costData[3][0]['이메일 참조인'];
    defaultData.BccEmail = costData[3][0]['이메일 숨은참조인'];
    emailPopupRef.current.open(defaultData);
  };

  return (
    <div style={{ display: 'flex', marginTop: '3px', width: '100%' }}>
      <div className="normal-report" style={{ marginRight: '5px', width: '70%' }}>
        <Toolbar>
          <Item location={'before'}>
            <Button
              icon="file"
              text="미리보기"
              onClick={() => {
                onExporting('preview');
              }}
            />
          </Item>
          <Item location={'after'}>
            <Button icon="email" text="메일 전송" onClick={onEmailClick} hint="수배정산서 이메일 전송" />
            <Button icon="exportpdf" text="PDF 출력" onClick={onPdfClick} hint="PDF 추출" />
            <Button
              icon="exportxlsx"
              text="Excel 출력"
              onClick={() => {
                onExporting('excel');
              }}
              hint="수배정산서 Excel 다운로드"
            />
            <Button icon="menu" text="내역 출력" onClick={onPaymentExporting} hint="카드/계좌 내역 Excel 다운로드" />
          </Item>
        </Toolbar>
        {previewUrl && <iframe src={previewUrl} width="100%" height="1000px" title="PDF 미리보기" />}
        <MainPivotGrid gridRef={mainPivotRef} dataSource={mainPivot} previewUrl={previewUrl} />
        <CardPivotGrid gridRef={cardPivotRef} dataSource={cardPivot} />
        <SalesDataGrid gridRef={salesRef} dataSource={salesDataSource} />
        <CashDataGrid gridRef={cashRef} dataSource={cashDataSource} />
        <CardDataGrid gridRef={cardRef} dataSource={cardDataSource} />
        <EmailPopup ref={emailPopupRef} onExporting={onExporting} costData={costData} />
      </div>
      <div style={{ marginRight: '5px', width: '30%' }}>
        <CommonDataGrid
          className={'dx-card wide-card'}
          dataSource={infoDataSource}
          showBorders={true}
          columnAutoWidth={true}
          allowColumnResizing={true}
          columnHidingEnabled={false}
          hoverStateEnabled={true}
          noDataText="사업소 정보가 없습니다."
          height={1000}
          isHeader={true}
          isExcel={false}
        >
          <Scrolling mode="virtual" rowRenderingMode="virtual" />
        </CommonDataGrid>
      </div>
    </div>
  );
};

export default ViewSalesCostReportExportPage;
