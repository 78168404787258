import moment from 'moment';
import { underlineImage, hiparkingImage } from '../base64';
const getFormula = (col, nRow, SalesCnt, isMulti, isCard) => {
  if (isMulti) {
    let refRow = isCard ? nRow - 1 : nRow;
    return `SUMIFS(매출!$H$3:$H$${SalesCnt + 8},
      매출!$J$3:$J$${SalesCnt + 8},$D$${nRow},
      매출!$K$3:$K$${SalesCnt + 8},$${col}$11,
      매출!$B$3:$B$${SalesCnt + 8},$B$${refRow})`;
  } else {
    return `SUMIFS(매출!$H$3:$H$${SalesCnt + 8},
      매출!$J$3:$J$${SalesCnt + 8},$B$${nRow},
      매출!$K$3:$K$${SalesCnt + 8},$${col}$11)
  `;
  }
};

export async function createWorksheet1(workbook, worksheet1, pivotDataSource, costData) {
  const costCenters = costData[3][0]['영업점목록'];
  const sCostCenterNames = JSON.parse(costCenters);
  const bIsMulti = sCostCenterNames.length > 1;

  const getValueFromTable = (costData, searchCode) => {
    const targetRow = costData.find(row => row.CostDetailCode === searchCode);
    return targetRow ? targetRow.Value : null;
  };
  const getNameFromTable = (costData, searchCode) => {
    const targetRow = costData.find(row => row.CostDetailCode === searchCode);
    return targetRow ? targetRow.CodeDesc : null;
  };
  const getCustomNameFromTable = (costData, searchCode) => {
    const targetRow = costData.find(row => row.CostDetailCode === searchCode);
    return targetRow ? targetRow.ExtraField1 : null;
  };

  const MergeCellsWithStyle = (worksheet, range) => {
    worksheet.unMergeCells(range);
    worksheet.mergeCells(range);
    worksheet.getCell(`${range.split(':')[0]}`).border = borderAll;
    worksheet.getCell(`${range.split(':')[0]}`).alignment = alignmentCenter;
  };

  function findFixedExpenseObject(node, searchString) {
    if (node.value === searchString) {
      return node;
    }
    for (const child of node.children) {
      const result = findFixedExpenseObject(child, searchString);
      if (result) {
        return result;
      }
    }
    return null;
  }

  const sCostCenterName = costData[3][0]['영업점명'];
  // 열의 기본 너비 설정 (예: 15)

  const fillGrey = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F5F5F5F5' } };
  const fillYellow = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFBF900' } };

  const pivotraw = await pivotDataSource.load().done();
  const nodeSales = findFixedExpenseObject(pivotraw.rows[0], '매출');
  const nodeCostFixed = pivotraw.rows.length > 1 ? findFixedExpenseObject(pivotraw.rows[1], '고정경비') : null;
  const nodeCostFloating = pivotraw.rows.length > 1 ? findFixedExpenseObject(pivotraw.rows[1], '변동경비') : null;
  const imageId1 = workbook.addImage({
    base64: underlineImage,
    extension: 'png',
  });
  const imageId2 = workbook.addImage({
    base64: hiparkingImage,
    extension: 'png',
  });

  const rnumFirst = 7;
  const cntSales = nodeSales.children.length;
  const cntCostFixed = nodeCostFixed ? nodeCostFixed.children.length : 0;
  const cntCostFloating = nodeCostFloating ? nodeCostFloating.children.length : 0;
  const cntCostTotal = cntCostFixed + cntCostFloating;
  const rnumLastRow =
    rnumFirst + 1 + cntSales + 1 + cntCostTotal + (cntCostFixed > 0 ? 1 : 0) + (cntCostFloating > 0 ? 1 : 0);

  const borderStyleDouble = { style: 'double', color: { argb: 'FF7E7E7E' } };
  const borderStyleThin = { style: 'thin', color: { argb: 'FF7E7E7E' } };
  const borderAll = {
    bottom: borderStyleThin,
    left: borderStyleThin,
    right: borderStyleThin,
    top: borderStyleThin,
  };
  const borderTopDouble = {
    bottom: borderStyleThin,
    left: borderStyleThin,
    right: borderStyleThin,
    top: borderStyleDouble,
  };

  const alignmentRight = { vertical: 'middle', horizontal: 'right' };
  const alignmentCenter = { vertical: 'middle', horizontal: 'center' };
  const alignmentLeft = { vertical: 'middle', horizontal: 'left' };
  const alignmentLeftShrinkText = { vertical: 'middle', horizontal: 'left', shrinkToFit: true };
  const alignmentCenterShrinkText = { vertical: 'middle', horizontal: 'center', shrinkToFit: true };
  const wrapTextAlignment = {
    wrapText: true,
    vertical: 'middle',
    horizontal: 'center',
  };

  worksheet1.columns = [
    { width: 1 },
    { width: bIsMulti ? 10 : 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: bIsMulti ? 3 : 4 },
    { width: bIsMulti ? 3 : 4 },
    { width: bIsMulti ? 3 : 4 },
    { width: bIsMulti ? 3 : 4 },
    { width: bIsMulti ? 3 : 4 },
    { width: bIsMulti ? 3 : 4 },
    { width: bIsMulti ? 3 : 4 },
    { width: bIsMulti ? 3 : 4 },
    { width: bIsMulti ? 3 : 4 },
    { width: bIsMulti ? 3 : 4 },
    { width: bIsMulti ? 3 : 4 },
    { width: bIsMulti ? 3 : 4 },
    { width: bIsMulti ? 3 : 4 },
    { width: bIsMulti ? 3 : 4 },
    { width: bIsMulti ? 3 : 4 },
    { width: bIsMulti ? 3 : 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 4 },
    { width: 1 },
  ];
  worksheet1.getRow(1).height = 10;
  worksheet1.getRow(2).height = 35;
  worksheet1.getRow(3).height = 5;
  for (let r = 5; r <= 27; r++) {
    worksheet1.getRow(r).height = 18;
  }

  worksheet1.addImage(imageId1, 'U2:Y2');
  worksheet1.addImage(imageId2, 'B3:Y3');

  worksheet1.getCell('B2').value = {
    richText: [
      {
        text: `${sCostCenterName} 주차장 운영 보고서`,
        font: { size: 20, name: '휴먼둥근헤드라인' },
      },
    ],
  };

  worksheet1.mergeCells('B2:T2');
  worksheet1.mergeCells('T10:Y10');
  worksheet1.mergeCells('U22:Y22');
  worksheet1.getCell('B2').alignment = alignmentCenterShrinkText;

  let nTitleNum = 1;
  let nSectionNum = 1;

  const sheet1Ranges = [
    'B5:E5',
    'F5:M5',
    'N5:Q5',
    'R5:Y5',
    'B6:E6',
    'F6:M6',
    'N6:Q6',
    'R6:Y6',
    'B7:E7',
    'F7:Y7',
    'B8:E8',
    'F8:M8',
    'N8:Q8',
    'R8:Y8',
    'B11:E11',
    'F11:I11',
    'J11:M11',
    'N11:Q11',
    'R11:U11',
    'V11:Y11',
  ];

  if (bIsMulti) {
    sheet1Ranges.push('B11:C11', 'D11:E11');
  }
  sheet1Ranges.forEach(range => {
    worksheet1.unMergeCells(range);
    worksheet1.mergeCells(range);
    worksheet1.getCell(`${range.split(':')[0]}`).border = borderAll;
    worksheet1.getCell(`${range.split(':')[0]}`).alignment = alignmentCenter;
  });

  const sStartDate = costData[3][0]['시작일'];
  const sEndDate = costData[3][0]['종료일'];
  const sParkingArea = costData[3][0]['주차면수'];
  const sAddress = costData[3][0]['주소'];
  const sCustomerName = costData[3][0]['수신처'];
  const sIsTaxFree = costData[3][0]['부가세없음'];
  const sIsDeficitCoverage = costData[3][0]['적자보전'];
  const sProfitOur = costData[3][0]['배분율-자사'];
  const sProfitUser = costData[3][0]['배분율-갑사'];
  const sSalesDeduction =
    costData[3][0]['매출차감금액'] > '' ? parseInt(costData[3][0]['매출차감금액'].replace(/,/g, '')) : 0;
  const sFixedRentalAmount = parseInt(getValueFromTable(costData[4], 'FixedRentalAmount'));
  const sFixedRentalCustomName = getCustomNameFromTable(costData[4], 'FixedRentalAmount');
  const sFixedRentalAmountName = sFixedRentalCustomName || getNameFromTable(costData[4], 'FixedRentalAmount');

  const sParkingSubsidyAmount = parseInt(getValueFromTable(costData[4], 'ParkingSubsidyAmount'));
  const sParkingSubsidyAmountCustomName = getCustomNameFromTable(costData[4], 'ParkingSubsidyAmount');
  const sParkingSubsidyAmountName =
    sParkingSubsidyAmountCustomName || getNameFromTable(costData[4], 'ParkingSubsidyAmount');

  const sCharge001 = parseInt(getValueFromTable(costData[5], '001'));
  const sCharge001CustomName = getCustomNameFromTable(costData[5], '001');
  const sCharge001Name = sCharge001CustomName || getNameFromTable(costData[5], '001');

  const sCharge002 = parseInt(getValueFromTable(costData[5], '002'));
  const sCharge002CustomName = getCustomNameFromTable(costData[5], '002');
  const sCharge002Name = sCharge002CustomName || getNameFromTable(costData[5], '002');

  const sCharge003 = parseInt(getValueFromTable(costData[5], '003'));
  const sCharge003CustomName = getCustomNameFromTable(costData[5], '003');
  const sCharge003Name = sCharge003CustomName || getNameFromTable(costData[5], '003');

  let bIsDeficit = false;

  const tempSalesTotal = costData[2].reduce((acc, obj) => {
    const value = parseInt(obj.실적);
    return obj.구분1 !== '원가' ? acc + value : acc;
  }, 0);
  const tempCostTotal = costData[2].reduce((acc, obj) => {
    const value = parseInt(obj.실적);
    return obj.구분1 === '원가' ? acc + value : acc;
  }, 0);
  bIsDeficit = tempSalesTotal - tempCostTotal - sSalesDeduction < 0;

  worksheet1.getCell('B5').value = '보고일자';
  worksheet1.getCell('B5').fill = fillGrey;
  worksheet1.getCell('F5').value = moment(new Date()).format('yyyy-MM-DD');
  worksheet1.getCell('F5').alignment = alignmentLeft;
  worksheet1.getCell('N5').value = '대상기간';
  worksheet1.getCell('N5').fill = fillGrey;
  worksheet1.getCell('R5').value = sStartDate + ' ~ ' + sEndDate;
  worksheet1.getCell('R5').alignment = alignmentLeft;

  worksheet1.getCell('B6').value = '영업점명';
  worksheet1.getCell('B6').fill = fillGrey;
  worksheet1.getCell('F6').value = sCostCenterName;
  worksheet1.getCell('F6').alignment = alignmentLeftShrinkText;
  worksheet1.getCell('N6').value = '주차면수';
  worksheet1.getCell('N6').fill = fillGrey;
  worksheet1.getCell('R6').value = sParkingArea;
  worksheet1.getCell('R6').alignment = alignmentLeft;

  worksheet1.getCell('B7').value = '소재지';
  worksheet1.getCell('B7').fill = fillGrey;
  worksheet1.getCell('F7').value = sAddress;
  worksheet1.getCell('F7').alignment = alignmentLeft;

  worksheet1.getCell('B8').value = '수신처';
  worksheet1.getCell('B8').fill = fillGrey;
  worksheet1.getCell('F8').value = sCustomerName;
  worksheet1.getCell('F8').alignment = alignmentLeftShrinkText;
  worksheet1.getCell('N8').value = '발신인';
  worksheet1.getCell('N8').fill = fillGrey;
  worksheet1.getCell('R8').value = '하이파킹 정산관리팀';
  worksheet1.getCell('R8').alignment = alignmentLeft;

  worksheet1.getCell('B10').value = `${nTitleNum++} 매출 현황`;
  worksheet1.getCell('T10').value = '(단위 : 원, VAT별도) ';
  worksheet1.getCell('T10').alignment = alignmentRight;
  if (bIsMulti) {
    worksheet1.getCell('B11').value = '영업점';
    worksheet1.getCell('D11').value = '구분';
  } else {
    worksheet1.getCell('B11').value = '구분';
  }
  worksheet1.getCell('F11').value = '정기권 매출';
  worksheet1.getCell('J11').value = '일반권 매출';
  worksheet1.getCell('N11').value = '할인권 매출';
  worksheet1.getCell('R11').value = '기타 매출';
  worksheet1.getCell('V11').value = '합 계';

  worksheet1.getCell('B11').fill = fillGrey;
  worksheet1.getCell('D11').fill = fillGrey;
  worksheet1.getCell('F11').fill = fillGrey;
  worksheet1.getCell('J11').fill = fillGrey;
  worksheet1.getCell('N11').fill = fillGrey;
  worksheet1.getCell('R11').fill = fillGrey;
  worksheet1.getCell('V11').fill = fillGrey;

  let nRow = 12;
  const subTotalRows = [];

  sCostCenterNames.forEach((value, index) => {
    let SalesCnt = costData[1].length;
    // 현금

    MergeCellsWithStyle(worksheet1, `F${nRow}:I${nRow}`);
    MergeCellsWithStyle(worksheet1, `J${nRow}:M${nRow}`);
    MergeCellsWithStyle(worksheet1, `N${nRow}:Q${nRow}`);
    MergeCellsWithStyle(worksheet1, `R${nRow}:U${nRow}`);
    MergeCellsWithStyle(worksheet1, `V${nRow}:Y${nRow}`);
    if (bIsMulti) {
      worksheet1.getCell(`B${nRow}`).value = value;
      worksheet1.getCell(`B${nRow}`).style = { font: { size: 9 } };
      MergeCellsWithStyle(worksheet1, `B${nRow}:C${nRow + 1}`);
      MergeCellsWithStyle(worksheet1, `D${nRow}:E${nRow}`);
      worksheet1.getCell(`D${nRow}`).value = '현금';
      worksheet1.getCell(`B${nRow}`).alignment = wrapTextAlignment;
    } else {
      MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
      worksheet1.getCell(`B${nRow}`).value = '현금';
    }
    worksheet1.getCell(`B${nRow}`).fill = fillGrey;
    worksheet1.getCell(`D${nRow}`).fill = fillGrey;

    worksheet1.getCell(`F${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`J${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`N${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`R${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`V${nRow}`).numFmt = '#,##0';

    worksheet1.getCell(`F${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`J${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`N${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`R${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`V${nRow}`).alignment = alignmentRight;

    worksheet1.getCell(`F${nRow}`).border = borderAll;
    worksheet1.getCell(`J${nRow}`).border = borderAll;
    worksheet1.getCell(`N${nRow}`).border = borderAll;
    worksheet1.getCell(`R${nRow}`).border = borderAll;
    worksheet1.getCell(`V${nRow}`).border = borderAll;

    let tmpFormula = '';
    let tmpCols = ['F', 'J', 'N', 'R'];

    tmpCols.forEach(col => {
      tmpFormula = getFormula(col, nRow, SalesCnt, bIsMulti);
      worksheet1.getCell(`${col}${nRow}`).value = {
        formula: tmpFormula,
      };
    });

    worksheet1.getCell(`V${nRow}`).value = { formula: `SUM(F${nRow}:U${nRow})` };

    nRow++;
    // 카드
    MergeCellsWithStyle(worksheet1, `F${nRow}:I${nRow}`);
    MergeCellsWithStyle(worksheet1, `J${nRow}:M${nRow}`);
    MergeCellsWithStyle(worksheet1, `N${nRow}:Q${nRow}`);
    MergeCellsWithStyle(worksheet1, `R${nRow}:U${nRow}`);
    MergeCellsWithStyle(worksheet1, `V${nRow}:Y${nRow}`);
    if (bIsMulti) {
      worksheet1.getCell(`D${nRow}`).value = '카드';
      MergeCellsWithStyle(worksheet1, `D${nRow}:E${nRow}`);
    } else {
      MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
      worksheet1.getCell(`B${nRow}`).value = '카드';
    }
    worksheet1.getCell(`B${nRow}`).fill = fillGrey;
    worksheet1.getCell(`D${nRow}`).fill = fillGrey;

    worksheet1.getCell(`F${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`J${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`N${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`R${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`V${nRow}`).numFmt = '#,##0';

    worksheet1.getCell(`F${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`J${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`N${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`R${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`V${nRow}`).alignment = alignmentRight;

    tmpCols.forEach(col => {
      tmpFormula = getFormula(col, nRow, SalesCnt, bIsMulti, 'Card');
      worksheet1.getCell(`${col}${nRow}`).value = {
        formula: tmpFormula,
      };
    });

    worksheet1.getCell(`V${nRow}`).value = { formula: `SUM(F${nRow}:U${nRow})` };

    if (bIsMulti) {
      nRow++;
      MergeCellsWithStyle(worksheet1, `F${nRow}:I${nRow}`);
      MergeCellsWithStyle(worksheet1, `J${nRow}:M${nRow}`);
      MergeCellsWithStyle(worksheet1, `N${nRow}:Q${nRow}`);
      MergeCellsWithStyle(worksheet1, `R${nRow}:U${nRow}`);
      MergeCellsWithStyle(worksheet1, `V${nRow}:Y${nRow}`);
      subTotalRows.push(nRow);
      worksheet1.getCell(`B${nRow}`).value = '소 계';
      worksheet1.getCell(`F${nRow}`).value = { formula: `SUM(F$${nRow - 2}:F${nRow - 1})` };
      worksheet1.getCell(`J${nRow}`).value = { formula: `SUM(J$${nRow - 2}:J${nRow - 1})` };
      worksheet1.getCell(`N${nRow}`).value = { formula: `SUM(N$${nRow - 2}:N${nRow - 1})` };
      worksheet1.getCell(`R${nRow}`).value = { formula: `SUM(R$${nRow - 2}:R${nRow - 1})` };
      worksheet1.getCell(`V${nRow}`).value = { formula: `SUM(F${nRow}:U${nRow})` };
      // worksheet1.getCell(`V${nRow}`).value = { formula: `SUM(V$${nRow - 2}:V${nRow - 1})` };

      worksheet1.getCell(`F${nRow}`).numFmt = '#,##0';
      worksheet1.getCell(`J${nRow}`).numFmt = '#,##0';
      worksheet1.getCell(`N${nRow}`).numFmt = '#,##0';
      worksheet1.getCell(`R${nRow}`).numFmt = '#,##0';
      worksheet1.getCell(`V${nRow}`).numFmt = '#,##0';

      worksheet1.getCell(`F${nRow}`).alignment = alignmentRight;
      worksheet1.getCell(`J${nRow}`).alignment = alignmentRight;
      worksheet1.getCell(`N${nRow}`).alignment = alignmentRight;
      worksheet1.getCell(`R${nRow}`).alignment = alignmentRight;
      worksheet1.getCell(`V${nRow}`).alignment = alignmentRight;

      MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
      worksheet1.getCell(`B${nRow}`).fill = fillGrey;

      nRow++;
    }
  });

  !bIsMulti && nRow++;

  MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
  MergeCellsWithStyle(worksheet1, `F${nRow}:I${nRow}`);
  MergeCellsWithStyle(worksheet1, `J${nRow}:M${nRow}`);
  MergeCellsWithStyle(worksheet1, `N${nRow}:Q${nRow}`);
  MergeCellsWithStyle(worksheet1, `R${nRow}:U${nRow}`);
  MergeCellsWithStyle(worksheet1, `V${nRow}:Y${nRow}`);

  worksheet1.getCell(`B${nRow}`).value = '매출합계(a)';
  worksheet1.getCell(`B${nRow}`).fill = fillGrey;
  worksheet1.getCell(`B${nRow}`).border = borderTopDouble;
  worksheet1.getCell(`F${nRow}`).border = borderTopDouble;
  worksheet1.getCell(`J${nRow}`).border = borderTopDouble;
  worksheet1.getCell(`N${nRow}`).border = borderTopDouble;
  worksheet1.getCell(`R${nRow}`).border = borderTopDouble;
  worksheet1.getCell(`V${nRow}`).border = borderTopDouble;

  worksheet1.getCell(`F${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`J${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`N${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`R${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`V${nRow}`).numFmt = '#,##0';

  worksheet1.getCell(`F${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`J${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`N${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`R${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`V${nRow}`).alignment = alignmentRight;

  let tmpCols = ['F', 'J', 'N', 'R', 'V'];
  if (bIsMulti) {
    tmpCols.forEach(col => {
      const tmpArr = subTotalRows.map(row => col + row);
      const tmpFormula = tmpArr.join('+');
      worksheet1.getCell(`${col}${nRow}`).value = { formula: `${tmpFormula}` };
    });
  } else {
    tmpCols.forEach(col => {
      worksheet1.getCell(`${col}${nRow}`).value = { formula: `SUM(${col}12:${col}${nRow - 1})` };
    });
  }
  nRow++;
  nRow++;
  worksheet1.getCell(`B${nRow++}`).value = `${nTitleNum++}.수익배분 정산`;
  // 비용 내역
  worksheet1.getCell(`B${nRow++}`).value = `  ${nSectionNum++}) 비용 내역`;

  MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
  MergeCellsWithStyle(worksheet1, `F${nRow}:J${nRow}`);
  MergeCellsWithStyle(worksheet1, `K${nRow}:O${nRow}`);
  MergeCellsWithStyle(worksheet1, `P${nRow}:T${nRow}`);
  MergeCellsWithStyle(worksheet1, `U${nRow}:Y${nRow}`);

  worksheet1.getCell(`B${nRow}`).value = '구분';
  worksheet1.getCell(`F${nRow}`).value = '공급가액';
  worksheet1.getCell(`K${nRow}`).value = '부가세';
  worksheet1.getCell(`P${nRow}`).value = '합계';
  worksheet1.getCell(`U${nRow}`).value = '비 고';
  worksheet1.getCell(`B${nRow}`).fill = fillGrey;
  worksheet1.getCell(`F${nRow}`).fill = fillGrey;
  worksheet1.getCell(`K${nRow}`).fill = fillGrey;
  worksheet1.getCell(`P${nRow}`).fill = fillGrey;
  worksheet1.getCell(`U${nRow}`).fill = fillGrey;

  nRow++;
  MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
  MergeCellsWithStyle(worksheet1, `F${nRow}:J${nRow}`);
  MergeCellsWithStyle(worksheet1, `K${nRow}:O${nRow}`);
  MergeCellsWithStyle(worksheet1, `P${nRow}:T${nRow}`);
  MergeCellsWithStyle(worksheet1, `U${nRow}:Y${nRow}`);

  worksheet1.getCell(`F${nRow}`).value = { formula: `지출!E${rnumLastRow}` };
  worksheet1.getCell(`K${nRow}`).value = { formula: `+ROUND(F${nRow}*0.1,0)` };
  worksheet1.getCell(`P${nRow}`).value = { formula: `F${nRow}+K${nRow}` };

  worksheet1.getCell(`P${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`K${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`F${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`P${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`K${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`F${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`B${nRow}`).fill = fillGrey;
  worksheet1.getCell(`B${nRow}`).value = '운영비용(b)';
  nRow++;
  nRow++;

  // 2) 수익배분내역
  worksheet1.getCell(`B${nRow++}`).value = `  ${nSectionNum++}) 수익배분내역`;
  MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
  MergeCellsWithStyle(worksheet1, `F${nRow}:J${nRow}`);
  MergeCellsWithStyle(worksheet1, `K${nRow}:O${nRow}`);
  MergeCellsWithStyle(worksheet1, `P${nRow}:T${nRow}`);
  MergeCellsWithStyle(worksheet1, `U${nRow}:Y${nRow}`);

  worksheet1.getCell(`E${nRow}`).fill = fillGrey;
  worksheet1.getCell(`F${nRow}`).fill = fillGrey;
  worksheet1.getCell(`K${nRow}`).fill = fillGrey;
  worksheet1.getCell(`P${nRow}`).fill = fillGrey;
  worksheet1.getCell(`U${nRow}`).fill = fillGrey;

  worksheet1.getCell(`B${nRow}`).value = '구분';
  worksheet1.getCell(`F${nRow}`).value = '수익 (a)-(b)';
  worksheet1.getCell(`K${nRow}`).value = sProfitUser + '% (갑)';
  worksheet1.getCell(`P${nRow}`).value = sProfitOur + '% (하이파킹)';
  worksheet1.getCell(`U${nRow++}`).value = '비 고';

  MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
  MergeCellsWithStyle(worksheet1, `F${nRow}:J${nRow}`);
  MergeCellsWithStyle(worksheet1, `K${nRow}:O${nRow}`);
  MergeCellsWithStyle(worksheet1, `P${nRow}:T${nRow}`);
  MergeCellsWithStyle(worksheet1, `U${nRow}:Y${nRow}`);

  const tmpSalesTotalRow = nRow - 9; //현재 nRow 기준으로 매출합계 셀 찾기
  const tmpCostTotalRow = nRow - 4; //현재 nRow 기준으로 공급가액 셀 찾기

  worksheet1.getCell(`F${nRow}`).value =
    sSalesDeduction > 0
      ? { formula: `V${tmpSalesTotalRow}-F${tmpCostTotalRow}-${sSalesDeduction}` }
      : { formula: `V${tmpSalesTotalRow}-F${tmpCostTotalRow}` };
  if (bIsDeficit) {
    worksheet1.getCell(`K${nRow}`).value = 0;
    worksheet1.getCell(`P${nRow}`).value = 0;
  } else {
    worksheet1.getCell(`K${nRow}`).value = {
      formula: `ROUND(+F${nRow}*${parseFloat(sProfitUser) / 100},0)`,
    };
    worksheet1.getCell(`P${nRow}`).value = { formula: `+F${nRow}-K${nRow}` };
    worksheet1.getCell(`K${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`P${nRow}`).numFmt = '#,##0';
  }

  worksheet1.getCell(`F${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`P${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`K${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`F${nRow}`).alignment = alignmentRight;

  worksheet1.getCell(`B${nRow}`).fill = fillGrey;
  worksheet1.getCell(`B${nRow}`).value = '수익배분';
  const nRow수익배분 = nRow;
  nRow++;
  nRow++;
  let nRow주차지원금 = 0;

  // 3) 주차 지원금 지급 내역 (있는 경우)
  if (sParkingSubsidyAmount) {
    worksheet1.getCell(`B${nRow++}`).value = `  ${nSectionNum++}) 지원금 지급 내역`;

    MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
    MergeCellsWithStyle(worksheet1, `F${nRow}:J${nRow}`);
    MergeCellsWithStyle(worksheet1, `K${nRow}:O${nRow}`);
    MergeCellsWithStyle(worksheet1, `P${nRow}:T${nRow}`);
    MergeCellsWithStyle(worksheet1, `U${nRow}:Y${nRow}`);
    worksheet1.getCell(`B${nRow}`).fill = fillGrey;
    worksheet1.getCell(`F${nRow}`).fill = fillGrey;
    worksheet1.getCell(`K${nRow}`).fill = fillGrey;
    worksheet1.getCell(`P${nRow}`).fill = fillGrey;
    worksheet1.getCell(`U${nRow}`).fill = fillGrey;

    worksheet1.getCell(`B${nRow}`).value = '구분';
    worksheet1.getCell(`F${nRow}`).value = '수익 (a)-(b)';
    worksheet1.getCell(`K${nRow}`).value = '(갑)';
    worksheet1.getCell(`P${nRow}`).value = '(하이파킹)';
    worksheet1.getCell(`U${nRow++}`).value = '비 고';

    nRow주차지원금 = nRow;
    MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
    MergeCellsWithStyle(worksheet1, `F${nRow}:J${nRow}`);
    MergeCellsWithStyle(worksheet1, `K${nRow}:O${nRow}`);
    MergeCellsWithStyle(worksheet1, `P${nRow}:T${nRow}`);
    MergeCellsWithStyle(worksheet1, `U${nRow}:Y${nRow}`);

    worksheet1.getCell(`F${nRow}`).value = { formula: `K${nRow}+P${nRow}` };
    worksheet1.getCell(`P${nRow}`).value = sParkingSubsidyAmount;

    worksheet1.getCell(`F${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`K${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`P${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`P${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`K${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`F${nRow}`).alignment = alignmentRight;

    worksheet1.getCell(`B${nRow}`).fill = fillGrey;
    worksheet1.getCell(`B${nRow}`).value = sParkingSubsidyAmountName;
    worksheet1.getCell(`E${nRow}`).alignment = alignmentCenterShrinkText;

    nRow++;
    nRow++;
  }
  // 3)4) 갑사 청구분
  worksheet1.getCell(`B${nRow++}`).value = sFixedRentalAmount
    ? `  ${nSectionNum}) 최종 청구 내역`
    : `  ${nSectionNum}) 갑사 청구분`;
  nSectionNum++;

  MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
  MergeCellsWithStyle(worksheet1, `F${nRow}:J${nRow}`);
  MergeCellsWithStyle(worksheet1, `K${nRow}:O${nRow}`);
  MergeCellsWithStyle(worksheet1, `P${nRow}:T${nRow}`);
  MergeCellsWithStyle(worksheet1, `U${nRow}:Y${nRow}`);
  worksheet1.getCell(`B${nRow}`).fill = fillGrey;
  worksheet1.getCell(`F${nRow}`).fill = fillGrey;
  worksheet1.getCell(`K${nRow}`).fill = fillGrey;
  worksheet1.getCell(`P${nRow}`).fill = fillGrey;
  worksheet1.getCell(`U${nRow}`).fill = fillGrey;

  worksheet1.getCell(`B${nRow}`).value = '구분';
  worksheet1.getCell(`F${nRow}`).value = '공급가액';
  worksheet1.getCell(`K${nRow}`).value = '부가세';
  worksheet1.getCell(`P${nRow}`).value = '합계';
  worksheet1.getCell(`U${nRow}`).value = '비 고';

  if (sFixedRentalAmount) {
    nRow++;
    MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
    MergeCellsWithStyle(worksheet1, `F${nRow}:J${nRow}`);
    MergeCellsWithStyle(worksheet1, `K${nRow}:O${nRow}`);
    MergeCellsWithStyle(worksheet1, `P${nRow}:T${nRow}`);
    MergeCellsWithStyle(worksheet1, `U${nRow}:Y${nRow}`);
    worksheet1.getCell(`B${nRow}`).fill = fillGrey;

    worksheet1.getCell(`B${nRow}`).value = sFixedRentalAmountName;
    worksheet1.getCell(`F${nRow}`).value = sFixedRentalAmount;
    worksheet1.getCell(`K${nRow}`).value = sFixedRentalAmount * 0.1;
    worksheet1.getCell(`P${nRow}`).value = sFixedRentalAmount * 1.1;
    worksheet1.getCell(`F${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`K${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`P${nRow}`).numFmt = '#,##0';

    worksheet1.getCell(`B${nRow}`).alignment = alignmentCenterShrinkText;
    worksheet1.getCell(`F${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`K${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`P${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`V${nRow}`).alignment = alignmentCenter;
  }
  nRow++;
  MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
  MergeCellsWithStyle(worksheet1, `F${nRow}:J${nRow}`);
  MergeCellsWithStyle(worksheet1, `K${nRow}:O${nRow}`);
  MergeCellsWithStyle(worksheet1, `P${nRow}:T${nRow}`);
  MergeCellsWithStyle(worksheet1, `U${nRow}:Y${nRow}`);

  const baseCell = bIsDeficit && sIsDeficitCoverage === '예' ? 'F' : 'K';
  const parkingSubsidy = sParkingSubsidyAmount ? `+P${nRow주차지원금}` : '';

  const tempFormula = `${baseCell}${nRow수익배분}${parkingSubsidy}`;

  worksheet1.getCell(`F${nRow}`).value = { formula: tempFormula };
  worksheet1.getCell(`K${nRow}`).value = { formula: `+ROUND(F${nRow}*0.1,0)` };
  if (sIsTaxFree === '예') {
    worksheet1.getCell(`K${nRow}`).value = { formula: `+ROUND(F${nRow}*0.1,0)*0` };
  }
  worksheet1.getCell(`P${nRow}`).value = { formula: `F${nRow}+K${nRow}` };

  worksheet1.getCell(`B${nRow}`).value = '수 익 배 분';
  worksheet1.getCell(`F${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`K${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`P${nRow}`).numFmt = '#,##0';
  worksheet1.getCell(`P${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`K${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`F${nRow}`).alignment = alignmentRight;
  worksheet1.getCell(`E${nRow}`).fill = fillGrey;

  if (sFixedRentalAmount) {
    nRow++;
    worksheet1.getCell(`B${nRow}`).fill = fillGrey;
    MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
    MergeCellsWithStyle(worksheet1, `F${nRow}:J${nRow}`);
    MergeCellsWithStyle(worksheet1, `K${nRow}:O${nRow}`);
    MergeCellsWithStyle(worksheet1, `P${nRow}:T${nRow}`);
    MergeCellsWithStyle(worksheet1, `U${nRow}:Y${nRow}`);

    worksheet1.getCell(`B${nRow}`).value = '최종 수익금';
    worksheet1.getCell(`F${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`K${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`P${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`V${nRow}`).value = ' 갑사 계산서 청구';
    worksheet1.getCell(`F${nRow}`).value = { formula: `F${nRow - 2}+F${nRow - 1}` };
    worksheet1.getCell(`K${nRow}`).value = { formula: `K${nRow - 2}+K${nRow - 1}` };
    worksheet1.getCell(`P${nRow}`).value = { formula: `P${nRow - 2}+P${nRow - 1}` };

    worksheet1.getCell(`B${nRow}`).alignment = alignmentCenter;
    worksheet1.getCell(`F${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`K${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`P${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`V${nRow}`).alignment = alignmentCenter;
  }

  if (sCharge001) {
    nRow++;
    worksheet1.getCell(`B${nRow}`).fill = fillGrey;
    MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
    MergeCellsWithStyle(worksheet1, `F${nRow}:J${nRow}`);
    MergeCellsWithStyle(worksheet1, `K${nRow}:O${nRow}`);
    MergeCellsWithStyle(worksheet1, `P${nRow}:T${nRow}`);
    MergeCellsWithStyle(worksheet1, `U${nRow}:Y${nRow}`);

    worksheet1.getCell(`B${nRow}`).value = sCharge001Name;
    worksheet1.getCell(`F${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`K${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`P${nRow}`).numFmt = '#,##0';
    worksheet1.getRow(nRow).height = 35;
    worksheet1.getCell(`V${nRow}`).fill = fillYellow;

    worksheet1.getCell(`F${nRow}`).value = sCharge001;
    worksheet1.getCell(`K${nRow}`).value = sCharge001 * 0.1;
    worksheet1.getCell(`P${nRow}`).value = sCharge001 * 1.1;
    worksheet1.getCell(`V${nRow}`).value = '하이파킹\n->갑사(계산서발급)';

    worksheet1.getCell(`B${nRow}`).alignment = alignmentCenterShrinkText;
    worksheet1.getCell(`F${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`K${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`P${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`V${nRow}`).alignment = wrapTextAlignment;
  }

  if (sCharge002) {
    nRow++;
    worksheet1.getCell(`B${nRow}`).fill = fillGrey;
    MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
    MergeCellsWithStyle(worksheet1, `F${nRow}:J${nRow}`);
    MergeCellsWithStyle(worksheet1, `K${nRow}:O${nRow}`);
    MergeCellsWithStyle(worksheet1, `P${nRow}:T${nRow}`);
    MergeCellsWithStyle(worksheet1, `U${nRow}:Y${nRow}`);

    worksheet1.getCell(`B${nRow}`).value = sCharge002Name;
    worksheet1.getCell(`F${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`K${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`P${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`U${nRow}`).value = '하이파킹\n->갑사(계산서발급)';
    worksheet1.getRow(nRow).height = 35;
    worksheet1.getCell(`U${nRow}`).fill = fillYellow;
    worksheet1.getCell(`F${nRow}`).value = sCharge002;
    worksheet1.getCell(`K${nRow}`).value = sCharge002 * 0.1;
    worksheet1.getCell(`P${nRow}`).value = sCharge002 * 1.1;

    worksheet1.getCell(`B${nRow}`).alignment = alignmentCenterShrinkText;
    worksheet1.getCell(`F${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`K${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`P${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`U${nRow}`).alignment = wrapTextAlignment;
  }
  if (sCharge003) {
    nRow++;
    worksheet1.getCell(`B${nRow}`).fill = fillGrey;
    MergeCellsWithStyle(worksheet1, `B${nRow}:E${nRow}`);
    MergeCellsWithStyle(worksheet1, `F${nRow}:J${nRow}`);
    MergeCellsWithStyle(worksheet1, `K${nRow}:O${nRow}`);
    MergeCellsWithStyle(worksheet1, `P${nRow}:T${nRow}`);
    MergeCellsWithStyle(worksheet1, `U${nRow}:Y${nRow}`);

    worksheet1.getCell(`B${nRow}`).value = sCharge003Name;
    worksheet1.getCell(`F${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`K${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`P${nRow}`).numFmt = '#,##0';
    worksheet1.getCell(`U${nRow}`).value = '하이파킹\n->갑사(계산서발급)';
    worksheet1.getRow(nRow).height = 35;
    worksheet1.getCell(`U${nRow}`).fill = fillYellow;
    worksheet1.getCell(`F${nRow}`).value = sCharge003;
    worksheet1.getCell(`K${nRow}`).value = sCharge003 * 0.1;
    worksheet1.getCell(`P${nRow}`).value = sCharge003 * 1.1;

    worksheet1.getCell(`B${nRow}`).alignment = alignmentCenterShrinkText;
    worksheet1.getCell(`F${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`K${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`P${nRow}`).alignment = alignmentRight;
    worksheet1.getCell(`U${nRow}`).alignment = wrapTextAlignment;
  }
  worksheet1.pageSetup.scale = 83;
  worksheet1.views = [{ state: 'normal', showGridLines: false }];
}
