import React, { useRef, useState, useEffect } from 'react';
import CommonDataGrid from 'common/default-data-grid';
import ParkingCostNormalReport from './normal/parking-cost-normal-report';
import DateBox from 'devextreme-react/date-box';
import DataSource from 'devextreme/data/data_source';
import moment from 'moment';
import axios from 'axios';
import { CheckBox } from 'devextreme-react/check-box';
import RadioGroup from 'devextreme-react/radio-group';
import CostcenterMaster from 'common/popup/costcenter-master';

import ParkingCostMonthlyReport from './monthly/parking-cost-monthly-report';
import { profitDistributionReportManualLink } from 'constants/hyperlink';
import { getNormalDateFormat } from 'utils/common';
import { LoadPanel as PSLoadPanel } from 'devextreme-react/load-panel';
import { Column, Scrolling } from 'devextreme-react/data-grid';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { Button } from 'devextreme-react/button';
import { common } from 'api';

import './sales-payment-report.scss';

const userId = localStorage.getItem('userId');

const ViewSalesCostReportPage = () => {
  const costcenterMasterRef = useRef({});
  const radioData = [
    { id: 'normal', text: '기본정산서' },
    { id: 'monthly', text: '월별정산서' },
  ];

  const [lookupText, setLookupText] = useState('');
  const [selectedRadio, setSelectedRadio] = useState('normal');
  const [selectedCostCenter, setSelectedCostCenter] = useState([]);
  //검색
  const [searchData, setSearchData] = useState({
    SalesMonth: moment().subtract(0, 'month').format('YYYY-MM'),
    CostCenterCode: '',
    ByPaymentDate: 'N',
    BySalesType: 'Y',
  });

  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  // lookup 사업소 목록
  const [costCenterType, setCostCenterType] = useState([]);
  // lookup 선택된 사업소
  const [costcenterCode, setCostcenterCode] = useState([]);
  // 운영 보고서 datagrid
  const [dataSource, setDataSource] = useState([]);
  const [infoDataSource, setInfoDataSource] = useState([]);
  const [infoDataSourceMonthly, setInfoDataSourceMonthly] = useState([]);

  const [costData, setCostData] = useState([]);
  const [costDataMonthly, setCostDataMonthly] = useState();

  const onValueChanged = e => {
    const ByPaymentDate = e.value ? 'Y' : 'N';
    setSearchData({ ...searchData, ByPaymentDate });
  };

  const onTypeChanged = e => {
    const BySalesType = e.value ? 'Y' : 'N';
    setSearchData({ ...searchData, BySalesType });
  };

  useEffect(() => {
    (async () => {
      const result = await common.costcenterData();
      if (result.isOk) {
        setCostCenterType(result.data);
      }
    })();
  }, []);

  const onSearch = async () => {
    if (costcenterCode.length === 0) {
      return alert('사업소를 먼저 선택해주세요');
    }
    if (selectedRadio === 'normal') {
      try {
        setLoadPanelVisible(true);
        const { SalesMonth, ByPaymentDate } = searchData;
        const costresult = await axios.post(`aggregation/getSalesFinalReport`, {
          CostCenterCode: costcenterCode,
          ByPaymentDate,
          SalesMonth,
          LoginID: userId,
        });
        setCostData(costresult.data);
        setDataSource(costresult.data[0]);
        const parkingDataTable = Object.entries(costresult.data[3][0]).map(([key, value]) => {
          const removeBrackets = str => (typeof str === 'string' ? str.replace(/[\[\]"]/g, '') : str);
          return {
            속성: key,
            값: key === '영업점목록' ? removeBrackets(value) : value,
          };
        });
        setInfoDataSource(parkingDataTable);
        setLoadPanelVisible(false);
      } catch (e) {
        setLoadPanelVisible(false);
        const { type, title, status, detail, instance } = e.response.data;
        throw Error(JSON.stringify({ type, title, status, detail, instance }));
      }
    } else if (selectedRadio === 'monthly') {
      try {
        const { SalesMonth, ByPaymentDate, BySalesType } = searchData;
        setLoadPanelVisible(true);
        const costresult = await axios.post(`aggregation/getSalesFinalMonthlyReport`, {
          CostCenterCode: costcenterCode,
          SalesMonth,
          ByPaymentDate,
          BySalesType,
          LoginID: userId,
        });
        setCostDataMonthly(costresult.data);
        const parkingDataTable = Object.entries(costresult.data[4][0]).map(([key, value]) => ({
          속성: key,
          값: value,
        }));
        setInfoDataSourceMonthly(parkingDataTable);
        setLoadPanelVisible(false);
      } catch (e) {
        setLoadPanelVisible(false);
        const { type, title, status, detail, instance } = e.response.data;
        throw Error(JSON.stringify({ type, title, status, detail, instance }));
      }
    }
  };
  useEffect(() => {
    if (selectedCostCenter.length === 0) {
      setLookupText('');
      return;
    }
    const codes = selectedCostCenter.map(a => a.CostCenterCode).join(', ');
    const length = selectedCostCenter.length;

    if (length > 1) {
      const { CostCenterCode: dpCode, CostCenterName: dpName } = selectedCostCenter[0];
      const dpText = `[${dpCode}]${dpName} 외 ${length - 1}건 선택됨`;
      setLookupText(dpText);
      setCostcenterCode(codes);
    } else {
      const dpText = selectedCostCenter
        .map((a, i) => `[${a.CostCenterCode}] ${a.CostCenterName}${i + 1 !== length ? ', ' : ''}`)
        .join('');
      setLookupText(dpText);
      setCostcenterCode(codes);
    }
  }, [selectedCostCenter]);

  const handleSalesMonth = e => {
    const yyyymmFormat = moment(getNormalDateFormat(e.value)).format('YYYY-MM');
    setSearchData({
      ...searchData,
      SalesMonth: yyyymmFormat,
    });
  };

  const onSelectionChanged = e => {
    setCostcenterCode(e.selectedItem.CostCenterCode);
    setLookupText(e.selectedItem.CostCenterName);
  };

  const groupedData = new DataSource({
    store: costCenterType,
    key: 'CostCenterCode',
  });

  const hideLoadPanel = () => {
    setLoadPanelVisible(false);
  };

  const onRadioValueChanged = e => {
    setSelectedRadio(e.value);
  };

  const fieldRender = () => {
    return (
      <div className="dx-lookup-field-wrapper">
        <div className={`dx-lookup-field ${!lookupText ? 'dx-lookup-empty' : ''}`}>
          {lookupText || '사업소를 선택하세요'}
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className={'content-block detail-title left-right-header'}>
        <div>
          <h2>운영 보고서 출력</h2>
        </div>
        <div>
          <a
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={'/#/report/mail-send-history'}
            target="blank"
          >
            메일 전송 이력 조회
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789' }}></i>
          <a
            className="manual-link"
            style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
            href={profitDistributionReportManualLink}
            target="blank"
          >
            메뉴얼 바로가기
          </a>
          <i className="fas fa-external-link-alt" style={{ color: '#627789' }}></i>
        </div>
      </div>
      <div className={'content-block customized-lookup'}>
        <div className={'dx-card'} style={{ padding: '20px 40px' }}>
          <CommonDataGrid
            className={'dx-card wide-card'}
            dataSource={dataSource}
            showBorders={true}
            columnAutoWidth={true}
            allowColumnResizing={true}
            columnHidingEnabled={false}
            hoverStateEnabled={true}
            height={235}
            isHeader={true}
            isExcel={false}
            visible={false}
            excelOptions={{
              sheetName: 'sheet',
              fileName: '사업소 확정 매출 조회 목록.xlsx',
            }}
            headerBefore={[
              <div className="label">등록월 :</div>,
              <DateBox
                type="date"
                pickerType="calendar"
                displayFormat="yyyy-MM"
                stylingMode="outlined"
                icon={true}
                calendarOptions={{
                  maxZoomLevel: 'year',
                }}
                width={100}
                onValueChanged={handleSalesMonth}
                value={searchData.SalesMonth}
                elementAttr={{ id: 'search-date-box' }}
              />,
              <Lookup
                labelMode="static"
                stylingMode="outlined"
                dataSource={groupedData}
                valueExpr="CostCenterCode"
                displayExpr="CostCenterName"
                searchExpr="CostCenterName"
                searchEnabled={true}
                dropDownCentered={false}
                width={400}
                placeholder="검색어를 입력해주세요"
                onSelectionChanged={onSelectionChanged}
                mode="search"
                fieldRender={fieldRender}
              >
                <DropDownOptions labelMode="static" stylingMode="outlined" showTitle={false} />
              </Lookup>,
              <Button
                stylingMode="text"
                text="복수 선택"
                onClick={() => {
                  costcenterMasterRef.current.open();
                }}
              />,
              <span />,
              <span />,
              <RadioGroup
                items={radioData}
                defaultValue={selectedRadio}
                displayExpr="text"
                valueExpr="id"
                layout="horizontal"
                onValueChanged={onRadioValueChanged}
              />,
            ]}
            headerAfter={[
              selectedRadio === 'monthly' && (
                <CheckBox defaultValue={true} text="매출유형(체크)/수금구분(해제)  " onValueChanged={onTypeChanged} />
              ),
              <CheckBox defaultValue={false} text="수금일 기준 조회   " onValueChanged={onValueChanged} />,
              <Button icon="search" text="조회" width={120} type="normal" stylingMode="contained" onClick={onSearch} />,
            ]}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <Column dataField={'HiParkingID'} caption={'P코드'} alignment={'center'} />
            <Column dataField={'CostCenterName'} caption={'사업소명'} alignment={'left'} />
            <Column dataField={'SalesMonth'} caption={'매출월'} alignment={'center'} width={'100'} />
            <Column dataField={'SalesCodeName'} caption={'수금구분'} alignment={'center'} width={'110'} />
            <Column caption={'매출'} alignment={'center'}>
              <Column dataField={'Sales-GeneralSales'} caption={'일반권'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Sales-SeasonTicket'} caption={'정기권'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Sales-DCTicket'} caption={'할인권'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Sales-EtcSales'} caption={'기타'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Sales-BtoB'} caption={'BtoB'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Sales-Total'} caption={'매출합계'} alignment={'left'} format="#,##0 원" />
            </Column>
            <Column caption={'운용비용'} alignment={'center'}>
              <Column dataField={'Cost-Fixed'} caption={'고정비'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Cost-Floating'} caption={'변동비'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Cost-Total'} caption={'비용합계'} alignment={'left'} format="#,##0 원" />
            </Column>
            <Column caption={'수익'} alignment={'center'}>
              <Column dataField={'Profit-Total'} caption={'수익합계'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Profit-User'} caption={'갑사수익'} alignment={'left'} format="#,##0 원" />
              <Column dataField={'Profit-Our'} caption={'자사수익'} alignment={'left'} format="#,##0 원" />
            </Column>
          </CommonDataGrid>
          {selectedRadio === 'normal' && (
            <ParkingCostNormalReport
              costData={costData}
              searchData={searchData}
              setLoadPanelVisible={setLoadPanelVisible}
              infoDataSource={infoDataSource}
            />
          )}
          {selectedRadio === 'monthly' && (
            <ParkingCostMonthlyReport
              costData={costDataMonthly}
              searchData={searchData}
              setLoadPanelVisible={setLoadPanelVisible}
              infoDataSource={infoDataSourceMonthly}
            />
          )}

          <CostcenterMaster
            ref={costcenterMasterRef}
            setSelectedCostCenter={setSelectedCostCenter}
            isMulti
            isSalesCostReport
          />
          <PSLoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position={{ of: '.content' }}
            onHiding={hideLoadPanel}
            visible={loadPanelVisible}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewSalesCostReportPage;
