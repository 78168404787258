import React, { useEffect, useRef, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import { manualLink } from 'constants/hyperlink';
import { encodedImage } from './base64';
import ScrollView from 'devextreme-react/scroll-view';
import Notice from './Notice';
import './home.scss';

export default function Home() {
  const [popupVisible, setPopupVisible] = useState(false);
  const popupRef = useRef({});
  const EXPIREVALUE = localStorage.getItem('lastVisit');

  useEffect(() => {
    const handleShowPopup = () => {
      let now = new Date();
      if (!EXPIREVALUE || EXPIREVALUE < now) {
        popupRef.current.open();
      }
    };
    window.setTimeout(handleShowPopup, 1000);
  }, []);

  const onClose = () => {
    setPopupVisible(false);
  };

  return (
    <>
      <div>
        <div className={'content-block detail-title left-right-header'}>
          <h2>Home</h2>
          <div>
            <a
              style={{ fontSize: 13, color: '#627789', paddingLeft: 15, paddingRight: 5, textDecoration: 'none' }}
              href={manualLink}
              target="blank"
            >
              메뉴얼 바로가기
            </a>
            <i className="fas fa-external-link-alt" style={{ color: '#627789' }}></i>
          </div>
        </div>
        <div className={'content-block'}>
          <div className={'dx-card responsive-paddings'}>
            <div style={{ fontSize: 16 }}>
              <b>
                &nbsp;&nbsp;■&nbsp;&nbsp;2024.01.01 현금영수증 발행 기능 추가 : (
                <a href="//docs.google.com/presentation/d/19DvGOdIVwuV8xhtLnm5Wf3Z6I5W7MVRbObFwR-qr1pU/edit?usp=sharing">매뉴얼 링크</a>)
                <br></br>
              </b>
              <b>
                &nbsp;&nbsp;■&nbsp;&nbsp;2024.05.13 가수금 관리 추가 : (
                <a href="//docs.google.com/presentation/d/1-_-NmSG-PUIspwrZnbJj36HrEryLhiGRkuguGqTQ8Pw/edit#slide=id.g28f611679cf_1_94">매뉴얼 링크</a>)
                <br></br>
              </b>
              <b>
                &nbsp;&nbsp;■&nbsp;&nbsp;2024.06.01 통합 결제 서비스 연동 : (
                <a href="//docs.google.com/presentation/d/1lwIhiP33V7l48hhG6rexw7izaOzg9mf_/edit?usp=sharing&ouid=102772354072081015818&rtpof=true&sd=true">매뉴얼 링크</a>)
                <br></br>
                <br></br>
              </b>
            </div>
            <div style={{ fontSize: 16 }}>
              <b>
                &nbsp;&nbsp;■&nbsp;&nbsp;복합대사 불가능 case 확인 부탁 드립니다.<br></br>
              </b>
            </div>
            <img src={encodedImage} alt=""></img>
            <br></br>
            <div style={{ fontSize: 22 }}>접속정보</div>
            <br></br>
            <div style={{ fontSize: 15 }}>
              <div>
                √ Site 주소: <a href="//live.hidms.co.kr/#/home">https://live.hidms.co.kr/#/home</a>
              </div>
              <div> √ ID: (본인 회사메일계정)</div>
              <div>
                &nbsp;&nbsp; ■ &nbsp;현장 외주직원분은 자신의 메일 계정을 등록하여 주시면 됩니다.(운영팀에서 등록해주신
                외주 직원분)
              </div>
            </div>
            <br></br>
            <div style={{ fontSize: 22 }}>교육영상</div>
            <br></br>
            <div style={{ fontSize: 15 }}>
              <div>
                * 교육영상과 Hi-DMS 매뉴얼 링크가 정상동작 하지 않은 경우,{' '}
                <a href="//docs.google.com/spreadsheets/d/1ubQaQsTmCGzfAClE4iuFnbIkEf3NbiO8UvZAds7TEJE/edit?pli=1#gid=0">
                  여기
                </a>
                를 클릭하셔서 '교육영상_매뉴얼' 탭을 참고하세요.
              </div>
              <div>
                √ Hi-DMS 교육 동영상(
                <a href="//drive.google.com/drive/folders/1Es6UcmQLVsx9thuIOTOXwh2_995Pb_cT?usp=drive_link">링크</a>)
              </div>
              <div>&nbsp;&nbsp;■ &nbsp; 정기권 관리</div>
              <div>
                &nbsp;&nbsp;■ &nbsp; 매출대사: 관제데이터 연동 사업소 경우 (다래, 넥스파, 아마노, AJ, 휴맥스팍스)
              </div>
              <div>&nbsp;&nbsp;■ &nbsp; 매출대사: 관제데이터 연동하지 않는 사업소 대사</div>
              <div>&nbsp;&nbsp;■ &nbsp; 기타매출관리</div>
              <div>&nbsp;&nbsp;■ &nbsp; 청구 세금계산서 발행</div>
              <div>&nbsp;&nbsp;■ &nbsp; 매출 취소</div>
              <div>&nbsp;&nbsp;■ &nbsp; 2022년도 매출에 대한 취소 처리</div>
              <div>&nbsp;&nbsp;■ &nbsp; 매출대사 부분반제 , 세금계산서 환불 기능</div>
              <div>
                √ Hi-DMS 매뉴얼(
                <a href="//docs.google.com/document/d/1ZI48hez6UdfRuuOBfIQqKmX3ckFZTs5U/edit#">링크</a>)
              </div>
              <div>
                √ 2023.06.25 추가 매뉴얼 (
                <a href="//docs.google.com/document/d/1FhaZpNyiZjwKGu_HPO-FFMbZlQ5THKqp/edit?usp=drive_link&ouid=102772354072081015818&rtpof=true&sd=true">
                  링크
                </a>
                )<br></br>
                &nbsp;&nbsp;-&nbsp;&nbsp; 매출 대사 기능 추가 : 부분 대사 기능, 매출 수금 내역 조회, 매출/결제 대사 표시
              </div>
              <div>
                √ Hi-DMS FAQ(
                <a href="//drive.google.com/file/d/17IVIwL5wzYUg7984sAPZlv2G93Ctj-I1/view?usp=share_link">링크</a>)
              </div>
            </div>
            <br></br>
            <div style={{ fontSize: 22 }}>운영지원</div>
            <br></br>
            <div style={{ fontSize: 15 }}>
              <div>
                √ 운영 요청 접수, 이슈 관리:{' '}
                <a href="//docs.google.com/spreadsheets/d/1ubQaQsTmCGzfAClE4iuFnbIkEf3NbiO8UvZAds7TEJE/edit?pli=1#gid=0">
                  링크
                </a>{' '}
                (요청하실 내용 등록 하시면, 처리 후 피드백 드림)
              </div>
              <div>√ 기타 문의는 정산관리팀 담당사업소 담당자에게 문의 하시거나 010-2983-1887(장혁 이사)로 연락 바랍니다.</div>
            </div>
          </div>
        </div>
        <Popup
          onHiding={onClose}
          visible={popupVisible}
          showCloseButton={true}
          showTitle={false}
          resizeEnabled={true}
          width="63%"
          height={620}
        >
          <ScrollView width="100%" height="100%">
            <div className={'dx-card responsive-paddings'} style={{ paddingTop: 3 }}>
              <Notice setPopupVisible={setPopupVisible} ref={popupRef} />
            </div>
          </ScrollView>
        </Popup>
      </div>
    </>
  );
}
