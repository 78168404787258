import DataGrid, { Column, Summary, TotalItem, Scrolling } from 'devextreme-react/data-grid';

export const CashDataGrid = props => {
  const { gridRef, dataSource } = props;
  return (
    <DataGrid
      ref={gridRef}
      className={'dx-card wide-card'}
      dataSource={dataSource}
      showBorders={false}
      hoverStateEnabled={false}
      stylingMode="outlined"
      visible={false}
      height={500}
    >
      <Scrolling mode="virtual" rowRenderingMode="virtual" />
      <Column dataField={'순번'} width={'40'} />
      <Column dataField={'가맹점명'} />
      <Column dataField={'입금일시'} />
      <Column dataField={'승인금액'} />
      <Column dataField={'계좌번호'} />
      <Column dataField={'은행명'} />
      <Column dataField={'거래번호'} />
      <Column dataField={'주문번호'} />
      <Column dataField={'가맹점ID'} />
      <Column dataField={'입금자명'} />
      <Summary>
        {/* <TotalItem column="가맹점명" summaryType="count" valueFormat="#,##" displayFormat="{0}" /> */}
        <TotalItem column="승인금액" summaryType="sum" valueFormat="#,##0" displayFormat="{0}" />
      </Summary>
    </DataGrid>
  );
};
