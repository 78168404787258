import { exportDataGrid, exportPivotGrid } from 'devextreme/excel_exporter';
import { autoFitColumnWidth } from 'utils/common';

export async function createWorksheetCash(worksheet1, gridcashRef, costData) {
  // 열의 기본 너비 설정 (예: 15)

  const borderStyleThin = { style: 'thin', color: { argb: 'FF7E7E7E' } };
  const borderAll = {
    bottom: borderStyleThin,
    left: borderStyleThin,
    right: borderStyleThin,
    top: borderStyleThin,
  };

  const alignmentCenter = { vertical: 'middle', horizontal: 'center' };
  const alignmentLeft = { vertical: 'middle', horizontal: 'left' };

  const fillGrey = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F5F5F5F5' } };

  const nCashLength = costData[6].length;
  // 가상계좌 시트
  const exporGridResultCash = exportDataGrid({
    component: gridcashRef.current.instance,
    worksheet: worksheet1,
    topLeftCell: { row: 2, column: 1 },
    customizeCell: options => {
      if (options.gridCell.column.dataType === 'number') {
        options.excelCell.style = { numFmt: '#,##0' };
      }
      if (options.gridCell.rowType === 'header') {
        options.excelCell.fill = fillGrey;
      }
      options.excelCell.font = { name: '맑은 고딕', size: 10 };
      options.excelCell.alignment = { wrapText: true };
      options.excelCell.border = borderAll;
    },
    autoFilterEnabled: true,
  });
  await exporGridResultCash;

  worksheet1.getColumn('B').alignment = alignmentCenter;
  worksheet1.getCell('A1').alignment = alignmentLeft;
  worksheet1.getRow(2).alignment = alignmentCenter;
  worksheet1.getCell('A1').value = {
    richText: [{ text: `계좌`, font: { name: '맑은 고딕', size: 10, bold: true } }], // 휴먼둥근헤드라인
  };

  worksheet1.getCell(`A${nCashLength + 3}`).value = '합계';
  if (nCashLength > 0) {
    worksheet1.getCell(`D${nCashLength + 3}`).value = { formula: `SUM(D3:D${nCashLength + 2})` };
  }
  worksheet1.getCell(`A${nCashLength + 3}`).fill = fillGrey;
  worksheet1.getCell(`B${nCashLength + 3}`).fill = fillGrey;
  worksheet1.getCell(`C${nCashLength + 3}`).fill = fillGrey;
  worksheet1.getCell(`D${nCashLength + 3}`).fill = fillGrey;
  worksheet1.getCell(`E${nCashLength + 3}`).fill = fillGrey;
  worksheet1.getCell(`F${nCashLength + 3}`).fill = fillGrey;
  worksheet1.getCell(`G${nCashLength + 3}`).fill = fillGrey;
  worksheet1.getCell(`H${nCashLength + 3}`).fill = fillGrey;
  worksheet1.getCell(`I${nCashLength + 3}`).fill = fillGrey;
  worksheet1.getCell(`J${nCashLength + 3}`).fill = fillGrey;
  worksheet1.pageSetup.scale = 54;
  autoFitColumnWidth(worksheet1);
  worksheet1.getColumn(1).width = 8;
}

export async function createWorksheetCashV2(worksheet2, pivotgridRef) {
  const borderStyleThin = { style: 'thin', color: { argb: 'FF7E7E7E' } };

  const fillGrey = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F5F5F5F5' } };
  const borderAll = {
    bottom: borderStyleThin,
    left: borderStyleThin,
    right: borderStyleThin,
    top: borderStyleThin,
  };
  const alignmentLeftShrinkText = { vertical: 'middle', horizontal: 'left', shrinkToFit: true };
  const alignmentCenterShrinkText = { vertical: 'middle', horizontal: 'center', shrinkToFit: true };

  const alignmentCenter = { vertical: 'middle', horizontal: 'center' };
  const alignmentLeft = { vertical: 'middle', horizontal: 'left' };
  const alignmentRight = { vertical: 'middle', horizontal: 'right' };

  // 카드 시트
  const exporGridResultCard = exportPivotGrid({
    component: pivotgridRef.current.instance,
    worksheet: worksheet2,
    topLeftCell: { row: 2, column: 1 },
    customizeCell: ({ pivotCell, excelCell }) => {
      console.log(pivotCell);
      if (pivotCell.type && pivotCell.type === 'GT') {
        excelCell.alignment = alignmentCenter;
      }
      if (pivotCell.dataType === 'number') {
        excelCell.style = { numFmt: '#,##0' };
      }
      if (pivotCell.area === 'row' && pivotCell.type === 'T') {
        excelCell.value = '';
      }
      excelCell.border = borderAll;
    },
  });
  await exporGridResultCard;
  worksheet2.spliceRows(2, 1);

  worksheet2.getColumn('A').alignment = alignmentCenterShrinkText;
  worksheet2.getCell('A1').alignment = alignmentLeft;

  worksheet2.getCell('A1').value = {
    richText: [{ text: `카드조회(일자별조회)`, font: { name: '맑은 고딕', size: 10, bold: true } }], // 휴먼둥근헤드라인
  };

  worksheet2.getCell('A2').value = '가맹점명';
  worksheet2.getCell('B2').value = '승인일자';
  worksheet2.getCell('A2').fill = fillGrey;
  worksheet2.getCell('B2').fill = fillGrey;
  worksheet2.getCell('C2').fill = fillGrey;
  worksheet2.getCell('D2').fill = fillGrey;
  worksheet2.getCell('E2').fill = fillGrey;
  worksheet2.getCell('F2').fill = fillGrey;
  worksheet2.getCell('G2').fill = fillGrey;
  worksheet2.getCell('H2').fill = fillGrey;
  worksheet2.getCell('I2').fill = fillGrey;
  worksheet2.getCell('J2').fill = fillGrey;
  worksheet2.getCell('K2').fill = fillGrey;
  worksheet2.getCell('L2').fill = fillGrey;
}
