import { PivotGrid, FieldChooser, FieldPanel, Scrolling } from 'devextreme-react/pivot-grid';
export const MainPivotGrid = props => {
  const { gridRef, dataSource, previewUrl } = props;
  return (
    <PivotGrid
      ref={gridRef}
      className={'dx-card wide-card'}
      dataSource={dataSource}
      showBorders={true}
      showColumnTotals={true}
      showRowTotals={true}
      showRowGrandTotals={false}
      hoverStateEnabled={false}
      height={1000}
      visible={!previewUrl}
      // onExporting={onExporting}
    >
      <Scrolling mode="virtual" showScrollbar="always" />
      <FieldPanel visible={true} allowFieldDragging={false} showFilterFields={false} />
      <FieldChooser enabled={false} />
    </PivotGrid>
  );
};
