/* 
Card : 기본 카드 내역
CardV2 : 기본 카드 내역을 일자별 정산하여 표시
CardV3 : 기본 카드 내역을 사업소별로 시트 구분하여 표시
*/

import { exportDataGrid, exportPivotGrid } from 'devextreme/excel_exporter';
import { autoFitColumnWidth } from 'utils/common';
const borderStyleThin = { style: 'thin', color: { argb: 'FF7E7E7E' } };

const fillGrey = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F5F5F5F5' } };
const borderAll = {
  bottom: borderStyleThin,
  left: borderStyleThin,
  right: borderStyleThin,
  top: borderStyleThin,
};
const alignmentLeftShrinkText = { vertical: 'middle', horizontal: 'left', shrinkToFit: true };
const alignmentCenterShrinkText = { vertical: 'middle', horizontal: 'center', shrinkToFit: true };

const alignmentCenter = { vertical: 'middle', horizontal: 'center' };
const alignmentLeft = { vertical: 'middle', horizontal: 'left' };
const alignmentRight = { vertical: 'middle', horizontal: 'right' };

export async function createWorksheetCard(worksheet2, gridcardRef, costData) {
  const nCardLength = costData[7].length;
  // 카드 시트
  const exporGridResultCard = exportDataGrid({
    component: gridcardRef.current.instance,
    worksheet: worksheet2,
    topLeftCell: { row: 2, column: 1 },
    customizeCell: options => {
      if (options.gridCell.column.dataType === 'number') {
        options.excelCell.style = { numFmt: '#,##0' };
      }
      if (options.gridCell.rowType === 'header') {
        options.excelCell.fill = fillGrey;
      }
      options.excelCell.font = { name: '맑은 고딕', size: 10 };
      options.excelCell.alignment = { wrapText: true };
      options.excelCell.border = borderAll;
    },
    autoFilterEnabled: true,
  });
  await exporGridResultCard;

  worksheet2.getColumn('B').alignment = alignmentCenter;
  worksheet2.getCell('A1').alignment = alignmentLeft;
  worksheet2.getRow(2).alignment = alignmentCenter;
  worksheet2.getCell('A1').value = {
    richText: [{ text: `카드조회`, font: { name: '맑은 고딕', size: 10, bold: true } }], // 휴먼둥근헤드라인
  };
  worksheet2.getCell(`A${nCardLength + 3}`).value = '합계';
  if (nCardLength > 0) {
    worksheet2.getCell(`F${nCardLength + 3}`).value = { formula: `SUM(F3:F${nCardLength + 2})` };
    worksheet2.getCell(`G${nCardLength + 3}`).value = { formula: `SUM(G3:G${nCardLength + 2})` };
    worksheet2.getCell(`H${nCardLength + 3}`).value = { formula: `SUM(H3:H${nCardLength + 2})` };
    worksheet2.getCell(`I${nCardLength + 3}`).value = { formula: `SUM(I3:I${nCardLength + 2})` };
    worksheet2.getCell(`J${nCardLength + 3}`).value = { formula: `SUM(J3:J${nCardLength + 2})` };
    worksheet2.getCell(`K${nCardLength + 3}`).value = { formula: `SUM(K3:K${nCardLength + 2})` };
  }
  worksheet2.getCell(`A${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`B${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`C${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`D${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`E${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`F${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`G${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`H${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`I${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`J${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`K${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`L${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`M${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`N${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`O${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`P${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`Q${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`R${nCardLength + 3}`).fill = fillGrey;
  worksheet2.getCell(`S${nCardLength + 3}`).fill = fillGrey;
  worksheet2.pageSetup.scale = 54;
  autoFitColumnWidth(worksheet2);
  worksheet2.getColumn(1).width = 8;
}

export async function createWorksheetCardV2(worksheet2, pivotgridRef, costData) {
  // 카드 시트
  const exporGridResultCard = exportPivotGrid({
    component: pivotgridRef.current.instance,
    worksheet: worksheet2,
    topLeftCell: { row: 2, column: 1 },
    customizeCell: ({ pivotCell, excelCell }) => {
      if (pivotCell.type && pivotCell.type === 'GT') {
        excelCell.alignment = alignmentCenter;
      }
      if (pivotCell.dataType === 'number') {
        excelCell.style = { numFmt: '#,##0' };
      }
      if (pivotCell.area === 'row' && pivotCell.type === 'T') {
        excelCell.value = '';
      }
      excelCell.border = borderAll;
    },
  });
  await exporGridResultCard;
  worksheet2.spliceRows(2, 1);

  worksheet2.getColumn('A').alignment = alignmentCenterShrinkText;
  worksheet2.getCell('A1').alignment = alignmentLeft;

  worksheet2.getCell('A1').value = {
    richText: [{ text: `카드조회(일자별조회)`, font: { name: '맑은 고딕', size: 10, bold: true } }], // 휴먼둥근헤드라인
  };

  worksheet2.getCell('A2').value = '가맹점명';
  worksheet2.getCell('B2').value = '승인일자';
  worksheet2.getCell('A2').fill = fillGrey;
  worksheet2.getCell('B2').fill = fillGrey;
  worksheet2.getCell('C2').fill = fillGrey;
  worksheet2.getCell('D2').fill = fillGrey;
  worksheet2.getCell('E2').fill = fillGrey;
  worksheet2.getCell('F2').fill = fillGrey;
  worksheet2.getCell('G2').fill = fillGrey;
  worksheet2.getCell('H2').fill = fillGrey;
  worksheet2.getCell('I2').fill = fillGrey;
  worksheet2.getCell('J2').fill = fillGrey;
  worksheet2.getCell('K2').fill = fillGrey;
  worksheet2.getCell('L2').fill = fillGrey;
}

export async function createWorksheetCardV3(worksheet2, data) {
  worksheet2.addRow();
  worksheet2.getCell('A1').value = {
    richText: [{ text: `카드`, font: { name: '맑은 고딕', size: 10, bold: true } }], // 휴먼둥근헤드라인
  };
  const currencyColumns = ['H', 'I', 'J', 'K', 'N', 'P'];
  const dataColumns = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S'];

  worksheet2.columns = [
    { width: 6 },
    { width: 6 },
    { width: 12 },
    { width: 14 },
    { width: 20 },

    { width: 12 },
    { width: 12 },

    { width: 14 },
    { width: 14 },
    { width: 14 },
    { width: 14 },

    { width: 10 },
    { width: 16 },

    { width: 14 },

    { width: 20 },
    { width: 14 },
    { width: 16 },
    { width: 20 },
    { width: 10 },
  ];

  const headers = [
    '순번',
    '상태',
    '가맹점ID',
    '가맹점명',
    '거래번호',

    '매입사',
    '발급사',
    '부가세',
    '수수료',
    'PG수수료',

    '승인금액',
    '승인번호',
    '승인일시',

    '정산금액',

    '주문번호',
    '취소금액',
    '취소일시',
    '카드번호',
    '할부개월',
  ];
  const headerValues = worksheet2.addRow(headers);
  let cnt = 1;
  data.forEach(item => {
    const row = headers.map(header => {
      return header === '순번' ? cnt : item[header] === null ? '' : item[header];
    });
    const dataValues = worksheet2.addRow(row);
    dataValues.eachCell(cell => {
      cell.border = borderAll;
      cell.font = { size: 10 };
    });
    cnt++;
  });

  dataColumns.forEach(column => {
    worksheet2.getCell(`${column}2`).fill = fillGrey;
    worksheet2.getColumn(column).alignment = alignmentLeftShrinkText;
    if (currencyColumns.includes(column)) {
      worksheet2.getColumn(column).eachCell(cell => {
        cell.numFmt = '#,##0';
        cell.alignment = alignmentRight;
      });
    }
  });

  // summary
  let nRow = worksheet2.rowCount;
  nRow++;
  worksheet2.getCell(`A${nRow}`).value = '합계';
  worksheet2.getCell(`A${nRow}`).font = { size: 10 };

  dataColumns.forEach(column => {
    worksheet2.getCell(`${column}${nRow}`).border = borderAll;
    worksheet2.getCell(`${column}${nRow}`).fill = fillGrey;
    if (currencyColumns.includes(column)) {
      worksheet2.getColumn(column).eachCell(cell => {
        cell.numFmt = '#,##0';
        cell.alignment = alignmentRight;
        cell.font = { size: 10 };
      });
      const sumCell = worksheet2.getCell(`${column}${nRow}`);
      sumCell.value = { formula: `SUM(${column}3:${column}${nRow - 1})` }; // 합계 계산
    }
  });

  headerValues.eachCell(cell => {
    cell.border = borderAll;
    cell.font = { size: 10 };
    cell.alignment = alignmentCenter;
  });
}
