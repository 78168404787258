import { exportPivotGrid } from 'devextreme/excel_exporter';
import { underlineImage, hiparkingImage } from '../base64';

const getFormula = (col, length, isMulti) => {
  let sTotalRow = 14;
  if (isMulti) {
    return `갑지!${col}${sTotalRow + 3 * (length - 1) + 1}`;
  } else {
    return `갑지!${col}${sTotalRow}`;
  }
};

export async function createWorksheet3(workbook, worksheet3, pivotDataSource, pivotgridRef, costData) {
  const costCenters = costData[3][0]['영업점목록'];
  const sCostCenterNames = JSON.parse(costCenters);
  const bIsMulti = sCostCenterNames.length > 1;

  const MergeCellsWithStyle = (worksheet, range) => {
    worksheet.unMergeCells(range);
    worksheet.mergeCells(range);
    worksheet.getCell(`${range.split(':')[0]}`).border = borderAll;
    worksheet.getCell(`${range.split(':')[0]}`).alignment = alignmentCenter;
  };

  const columnToName = columnNumber => {
    let columnName = '';
    while (columnNumber > 0) {
      let remainder = (columnNumber - 1) % 26;
      columnName = String.fromCharCode(65 + remainder) + columnName;
      columnNumber = Math.floor((columnNumber - 1) / 26);
    }
    return columnName;
  };

  function findFixedExpenseObject(node, searchString) {
    if (node.value === searchString) {
      return node;
    }
    for (const child of node.children) {
      const result = findFixedExpenseObject(child, searchString);
      if (result) {
        return result;
      }
    }
    return null;
  }

  function findCellWithExactValue(worksheet, targetValue) {
    let foundCell = null;

    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
        if (cell.value === targetValue) {
          foundCell = { row: rowNumber, column: colNumber, address: cell.address };
          return false; // 셀을 찾았으므로 루프 중단
        }
      });
      if (foundCell) return false; // 셀을 찾았으므로 루프 중단
    });

    return foundCell;
  }
  function findCellWithLikeValue(worksheet, targetValue) {
    let foundCell = null;

    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      row.eachCell({ includeEmpty: false }, (cell, colNumber) => {
        if (cell.value && cell.value.toString().includes(targetValue)) {
          foundCell = { row: rowNumber, column: colNumber, address: cell.address };
          return false; // 셀을 찾았으므로 루프 중단
        }
      });
      if (foundCell) return false; // 셀을 찾았으므로 루프 중단
    });

    return foundCell;
  }
  const sCostCenterName = costData[3][0]['영업점명'];
  const sCreditCardCommision = costData[3][0]['신용카드 수수료'];
  const sMaintenanceFee = costData[3][0]['관리비율'];
  const sMaintenanceFeeOfCost = costData[3][0]['관리비율_경비'];

  const pivotraw = await pivotDataSource.load().done();
  const nodeSales = findFixedExpenseObject(pivotraw.rows[0], '매출');
  const nodeCostFixed = pivotraw.rows.length > 1 ? findFixedExpenseObject(pivotraw.rows[1], '고정경비') : null;
  const nodeCostFloating = pivotraw.rows.length > 1 ? findFixedExpenseObject(pivotraw.rows[1], '변동경비') : null;
  const imageId1 = workbook.addImage({
    base64: underlineImage,
    extension: 'png',
  });
  const imageId2 = workbook.addImage({
    base64: hiparkingImage,
    extension: 'png',
  });

  const rnumFirst = 7;
  const cntSales = nodeSales.children.length;
  const cntCostFixed = nodeCostFixed ? nodeCostFixed.children.length : 0;
  const cntCostFloating = nodeCostFloating ? nodeCostFloating.children.length : 0;
  const cntCostTotal = cntCostFixed + cntCostFloating;
  const rnumLastRow =
    rnumFirst + 1 + cntSales + 1 + cntCostTotal + (cntCostFixed > 0 ? 1 : 0) + (cntCostFloating > 0 ? 1 : 0);

  const fillGrey = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F5F5F5F5' } };

  const borderStyleThin = { style: 'thin', color: { argb: 'FF7E7E7E' } };
  const borderStyleNone = { style: 'none' };
  const borderAll = {
    bottom: borderStyleThin,
    left: borderStyleThin,
    right: borderStyleThin,
    top: borderStyleThin,
  };
  const borderRightEmpty = {
    bottom: borderStyleThin,
    left: borderStyleThin,
    right: borderStyleNone,
    top: borderStyleThin,
  };
  const borderLeftEmpty = {
    bottom: borderStyleThin,
    left: borderStyleNone,
    right: borderStyleThin,
    top: borderStyleThin,
  };

  const alignmentCenter = { vertical: 'middle', horizontal: 'center' };
  const alignmentLeft = { vertical: 'middle', horizontal: 'left' };
  const alignmentLeftShrinkText = { vertical: 'middle', horizontal: 'left', shrinkToFit: true };
  const alignmentCenterShrinkText = { vertical: 'middle', horizontal: 'center', shrinkToFit: true };

  const wrapTextAlignment = {
    wrapText: true,
    vertical: 'middle',
    horizontal: 'center',
  };

  // 지출 시트
  const exporPivotResult = exportPivotGrid({
    component: pivotgridRef.current.instance,
    worksheet: worksheet3,
    topLeftCell: { row: rnumFirst, column: 2 },
    customizeCell: ({ pivotCell, excelCell }) => {
      if (pivotCell.type && pivotCell.type === 'D') {
        excelCell.alignment = alignmentCenter;
      }
      if (pivotCell.dataType === 'number') {
        excelCell.style = { numFmt: '#,##0' };
      }
      if (pivotCell.columnIndex && pivotCell.columnIndex === 4) {
        excelCell.alignment = alignmentLeft;
      }
      excelCell.border = borderAll;
    },
  });
  await exporPivotResult;

  worksheet3.columns = [
    { width: 1 },
    { width: 10 },
    { width: 7 },
    { width: 20 },
    { width: 23 },
    { width: 10 },
    { width: 25 },
    { width: 1 },
  ];
  worksheet3.rows = [{ height: 4 }, { height: 4 }, { height: 1 }, { height: 4 }];
  for (let r = 10; r < rnumLastRow; r++) {
    worksheet3.getRow(r).height = 18;
  }
  let rowFirstSales = rnumFirst + 1;
  let rowLastSales = rnumFirst + cntSales + 1;
  let rowFirstCost = rowLastSales + 1; //rnumFirst + cntSales + 1 + 1;
  let rowFirstCostFixed = cntCostFixed > 0 ? rowLastSales + 1 : -1; //rnumFirst + cntSales + 1 + 1;
  let rowLastCostFixed = cntCostFixed > 0 ? rowLastSales + cntCostFixed + 1 : -1; //rnumFirst + cntSales + 1 + cntCostFixed + 1;
  let rowFirstCostFluent = cntCostFloating > 0 ? rowFirstCost + (cntCostFixed > 0 ? cntCostFixed + 1 : 0) : -1; // rnumFirst + cntSales + 1 + cntCostFixed + 1 + 1;
  let rowLastCostFluent = cntCostFloating > 0 ? rowFirstCostFluent + cntCostFloating : -1;
  let rowLastCost = rowFirstCost + cntCostTotal + 1;

  worksheet3.getColumn('D').alignment = alignmentCenterShrinkText;
  worksheet3.getColumn('F').alignment = alignmentLeftShrinkText;

  worksheet3.addImage(imageId1, 'G1:G2');
  worksheet3.addImage(imageId2, 'B3:G3');

  worksheet3.spliceRows(7, 1);
  worksheet3.spliceRows(rowLastSales, 1);
  worksheet3.spliceRows(rnumLastRow + 1, 1);
  worksheet3.mergeCells('B2:F2');

  worksheet3.getCell('B2').value = {
    richText: [
      {
        text: `${sCostCenterName} 주차장 운영 보고서   `,
        font: { size: 20, name: '휴먼둥근헤드라인' },
      },
    ], // 휴먼둥근헤드라인
  };

  worksheet3.getCell('B2').alignment = alignmentCenterShrinkText;
  worksheet3.getRow(2).height = 25;
  worksheet3.getRow(3).height = 5;

  worksheet3.getCell('B5').value = {
    richText: [{ text: '2. 운영관리비 정산', font: { size: 12 } }],
  };
  worksheet3.unMergeCells('B7:G7');
  worksheet3.mergeCells('B7:D7');
  worksheet3.getCell('B7').value = '구 분';
  worksheet3.getCell('E7').value = '당월 실적';
  worksheet3.getCell('F7').value = '산출 근거';

  let tmpFormula = '';

  tmpFormula = getFormula('F', sCostCenterNames.length, bIsMulti);
  worksheet3.getCell('E8').value = {
    formula: tmpFormula,
  };
  tmpFormula = getFormula('J', sCostCenterNames.length, bIsMulti);
  worksheet3.getCell('E9').value = {
    formula: tmpFormula,
  };
  tmpFormula = getFormula('N', sCostCenterNames.length, bIsMulti);
  worksheet3.getCell('E10').value = {
    formula: tmpFormula,
  };

  let rowEtcStartRow = 11;
  let SalesCnt = costData[1].length;

  for (let i = 0; i < cntSales - 3; i++) {
    tmpFormula = `SUMIF(매출!$D$3:$D$${SalesCnt + 8}, LEFT(D${rowEtcStartRow + i}, LEN(D${
      rowEtcStartRow + i
    })-3), 매출!$H$3:$H$${SalesCnt + 8})`;
    worksheet3.getCell(`E${rowEtcStartRow + i}`).value = {
      formula: tmpFormula,
    };
  }

  worksheet3.getCell('B7').fill = fillGrey;
  worksheet3.getCell('E7').fill = fillGrey;
  worksheet3.getCell('F7').fill = fillGrey;

  worksheet3.unMergeCells(`B8:C${8 + cntSales}`);
  worksheet3.mergeCells(`B8:C${8 + cntSales}`);

  worksheet3.unMergeCells(`B${rowFirstCost}:B${rnumLastRow}`);
  worksheet3.mergeCells(`B${rowFirstCost}:B${rnumLastRow}`);
  if (cntCostTotal > 0) {
    worksheet3.unMergeCells(`C${rowFirstCost}:C${rnumFirst + 1 + cntSales + 1 + cntCostFixed}`);
    worksheet3.mergeCells(`C${rowFirstCost}:C${rnumFirst + 1 + cntSales + 1 + cntCostFixed}`);
  }
  if (rowFirstCostFluent > 0) {
    worksheet3.unMergeCells(`C${rowFirstCostFluent}:C${rowLastCostFluent}`);
    worksheet3.mergeCells(`C${rowFirstCostFluent}:C${rowLastCostFluent}`);
  }

  for (let row = cntSales; row <= rnumLastRow + 1; row++) {
    const cellAddress = `F${row}:G${row}`;
    worksheet3.unMergeCells(cellAddress);
    worksheet3.mergeCells(cellAddress);
  }
  worksheet3.getCell(`B${rowFirstSales}`).value = '매출\n(VAT 별도)';
  if (rowFirstCostFixed > 0) worksheet3.getCell(`C${rowFirstCost}`).value = '고정\n경비';
  if (rowFirstCostFluent > 0) worksheet3.getCell(`C${rowFirstCostFluent}`).value = '변동\n경비';

  worksheet3.getCell(`B${rowFirstSales}`).fill = fillGrey;
  worksheet3.getCell(`B${rowFirstCost}`).fill = fillGrey;
  worksheet3.getCell(`C${rowFirstCost}`).fill = fillGrey;
  if (rowFirstCostFluent > 0) worksheet3.getCell(`C${rowFirstCostFluent}`).fill = fillGrey;

  worksheet3.getCell(`D${rowLastSales}`).value = '매출 계';
  worksheet3.getCell(`F${rowLastSales}`).value = '';
  worksheet3.getCell(`E${rowLastSales}`).value = { formula: `SUM(E8:E${rowLastSales - 1})` };

  if (rowLastCostFixed > 0) {
    worksheet3.getCell(`D${rowLastCostFixed}`).value = '고정경비 소계';
    worksheet3.getCell(`E${rowLastCostFixed}`).value = {
      formula: `SUM(E${rowFirstCost}:E${rowLastCostFixed - 1})`,
    };
  }
  if (rowLastCostFluent > 0) {
    worksheet3.getCell(`D${rowLastCostFluent}`).value = '변동경비 소계';
    worksheet3.getCell(`E${rowLastCostFluent}`).value = {
      formula: `SUM(E${rowFirstCostFluent}:E${rowLastCostFluent - 1})`,
    };
  }

  worksheet3.getCell(`C${rnumLastRow}`).value = '';
  worksheet3.getCell(`D${rnumLastRow}`).value = '원가 계';

  if (rowLastCostFixed > 0 && rowLastCostFluent > 0) {
    worksheet3.getCell(`E${rnumLastRow}`).value = { formula: `E${rowLastCostFixed}+E${rowLastCostFluent}` };
    worksheet3.getCell(`F${rowLastCostFixed}`).value = '';
    worksheet3.getCell(`F${rowLastCostFluent}`).value = '';
  } else if (rowLastCostFixed > 0) {
    worksheet3.getCell(`E${rnumLastRow}`).value = { formula: `E${rowLastCostFixed}` };
    worksheet3.getCell(`F${rowLastCostFixed}`).value = '';
  } else if (rowLastCostFluent > 0) {
    worksheet3.getCell(`E${rnumLastRow}`).value = { formula: `E${rowLastCostFluent}` };
    worksheet3.getCell(`F${rowLastCostFluent}`).value = '';
  } else {
    worksheet3.getCell(`E${rnumLastRow}`).value = 0;
  }
  worksheet3.getCell(`F${rnumLastRow}`).value = '';
  worksheet3.getCell(`D${rowLastSales}`).fill = fillGrey;
  worksheet3.getCell(`C${rnumLastRow}`).fill = fillGrey;
  worksheet3.getCell(`D${rnumLastRow}`).fill = fillGrey;

  const cellCardComission = findCellWithExactValue(worksheet3, '신용카드 수수료');
  if (cellCardComission && cellCardComission.address !== null) {
    if (bIsMulti) {
      let cardTotalRow = 13;
      let tmpArr = [];
      let tmpFormula = '';
      let length = sCostCenterNames.length;
      for (let i = 0; i < length; i++) {
        tmpArr.push(`갑지!V${cardTotalRow + 3 * i}`);
      }
      tmpFormula = tmpArr.join('+');
      worksheet3.getCell(`${columnToName(cellCardComission.column + 1)}${cellCardComission.row}`).value = {
        formula: `(${tmpFormula})*${sCreditCardCommision || 1}%`,
      };
    } else {
      worksheet3.getCell(`${columnToName(cellCardComission.column + 1)}${cellCardComission.row}`).value = {
        formula: `갑지!V13*${sCreditCardCommision || 0}%`,
      };
    }
  }
  const cellMaintenanceFee = findCellWithLikeValue(worksheet3, '관리비율');
  if (cellMaintenanceFee && cellMaintenanceFee.address !== null) {
    let length = sCostCenterNames.length;
    let totalRow = bIsMulti ? 12 + length * 3 : 14;
    worksheet3.getCell(`${columnToName(cellMaintenanceFee.column - 2)}${cellMaintenanceFee.row}`).value = {
      formula: `갑지!V${totalRow}*${sMaintenanceFee || 0}%`,
    };
  }

  const cellCreditcardComission = findCellWithLikeValue(worksheet3, '신용카드 수수료의');
  const cellMaintenanceFeeOFCost = findCellWithLikeValue(worksheet3, '비용(카드수수료 제외)의');
  if (cellMaintenanceFeeOFCost && cellMaintenanceFeeOFCost.address !== null) {
    // console.log(cellCreditcardComission.address, rowFirstCostFluent);
    if (cntCostFixed === 0 && cellMaintenanceFeeOFCost.row === rowFirstCostFluent) {
      // 관리비 부여 대상인 고정비, 변동비 없는 경우는 제외
    } else if (cntCostFixed === 0 && cellCreditcardComission && cellCreditcardComission.row === rowFirstCostFluent) {
      // 관리비 부여 대상인 고정비, 변동비 첫번째가 신용카드 수수료인 경우는 제외
    } else {
      const rangelMaintenanceFeeOFCost = `${
        rowLastCostFixed > 0 ? 'E' + rowLastCostFixed : 'E' + rowFirstCostFluent
      }:E${cellMaintenanceFeeOFCost.row - (cellCreditcardComission ? 2 : 1)}`;
      worksheet3.getCell(`${columnToName(cellMaintenanceFeeOFCost.column - 2)}${cellMaintenanceFeeOFCost.row}`).value =
        {
          formula: `ROUND(SUM(${rangelMaintenanceFeeOFCost})*${sMaintenanceFeeOfCost || 0}%,0)`,
        };
    }
  }

  // 손익 계
  worksheet3.getCell(`B${rnumLastRow + 1}`).fill = fillGrey;
  worksheet3.getCell(`C${rnumLastRow + 1}`).fill = fillGrey;
  worksheet3.getCell(`D${rnumLastRow + 1}`).fill = fillGrey;

  worksheet3.getCell(`B${rnumLastRow + 1}`).value = '손익 계';
  MergeCellsWithStyle(worksheet3, `B${rnumLastRow + 1}:D${rnumLastRow + 1}`);

  if (cntCostTotal > 0) {
    worksheet3.getCell(`E${rnumLastRow + 1}`).value = { formula: `E${rowLastSales}-E${rnumLastRow}` };
  } else {
    worksheet3.getCell(`E${rnumLastRow + 1}`).value = { formula: `E${rowLastSales}` };
  }
  worksheet3.getCell(`E${rnumLastRow + 1}`).style = { numFmt: '#,##0' };
  if (rowLastCostFixed > 0) {
    worksheet3.getCell(`C${rowLastCostFixed}`).border = borderRightEmpty;
    worksheet3.getCell(`D${rowLastCostFixed}`).border = borderLeftEmpty;
    worksheet3.getCell(`C${rowLastCostFixed}`).alignment = alignmentCenter;
    worksheet3.getCell(`D${rowLastCostFixed}`).alignment = alignmentCenter;
  }
  if (rowLastCostFluent > 0) {
    worksheet3.getCell(`C${rowLastCostFluent}`).border = {
      bottom: borderStyleThin,
      left: borderStyleThin,
      right: borderStyleThin,
      top: borderStyleThin,
    };
    worksheet3.getCell(`C${rowLastCostFluent}`).border = borderRightEmpty;
    worksheet3.getCell(`C${rowLastCostFluent}`).border = {
      bottom: borderStyleThin,
      left: null,
      right: null,
      top: borderStyleThin,
    };
    worksheet3.getCell(`D${rowLastCostFluent}`).border = {
      bottom: borderStyleThin,
      left: null,
      right: null,
      top: borderStyleThin,
    };
    worksheet3.getCell(`C${rowLastCostFluent}`).border = {
      left: borderStyleThin,
      right: borderStyleNone,
    };
    worksheet3.getCell(`D${rowLastCostFluent}`).alignment = alignmentCenter;
    worksheet3.getCell(`C${rowFirstCostFluent}`).alignment = wrapTextAlignment;
  }
  worksheet3.getCell(`B${rowFirstSales}`).border = borderRightEmpty;
  worksheet3.getCell(`C${rowFirstCost}`).border = borderRightEmpty;
  worksheet3.getCell(`B${rowFirstCost}`).border = borderRightEmpty;
  worksheet3.getCell(`C${rnumLastRow}`).border = {
    bottom: borderStyleThin,
    left: borderStyleNone,
    right: borderStyleNone,
    top: borderStyleThin,
  };
  worksheet3.getCell(`D${rnumLastRow}`).border = {
    bottom: borderStyleThin,
    left: borderStyleNone,
    right: borderStyleNone,
    top: borderStyleThin,
  };

  worksheet3.getCell(`D${rowLastSales}`).border = borderLeftEmpty;

  worksheet3.getCell(`G${rnumLastRow}`).border = borderAll;
  worksheet3.getCell(`E${rnumLastRow}`).border = borderAll;
  worksheet3.getCell(`G${rnumLastRow + 1}`).border = borderAll;
  worksheet3.getCell(`E${rnumLastRow + 1}`).border = borderAll;

  worksheet3.getCell(`B${rnumFirst}`).alignment = alignmentCenter;
  worksheet3.getCell(`E${rnumFirst}`).alignment = alignmentCenter;
  worksheet3.getCell(`F${rnumFirst}`).alignment = alignmentCenter;
  worksheet3.getCell(`B${rowFirstSales}`).alignment = alignmentCenter;
  worksheet3.getCell(`B${rowLastSales}`).alignment = alignmentCenter;
  worksheet3.getCell(`C${rowLastSales}`).alignment = alignmentCenter;
  worksheet3.getCell(`D${rowLastSales}`).alignment = alignmentCenter;
  worksheet3.getCell(`D${rnumLastRow}`).alignment = alignmentCenter;

  worksheet3.getCell(`B${rowFirstSales}`).alignment = wrapTextAlignment;
  worksheet3.getCell(`C${rowFirstCost}`).alignment = wrapTextAlignment;

  if (rowLastCostFixed > 0) {
    worksheet3.getCell(`D${rowLastCostFixed}`).fill = fillGrey;
  }
  if (rowLastCostFluent > 0) {
    worksheet3.getCell(`D${rowLastCostFluent}`).fill = fillGrey;
  }
  worksheet3.views = [{ state: 'normal', showGridLines: false }];
  worksheet3.pageSetup.scale = 84;
}
